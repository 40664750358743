import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import VideoFile from "../../assets/video/header_reiki_bg.mp4";

export default function Header() {
  const [isLoading, setIsLoading] = useState(true);

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        {isLoading && (
          <LoaderWrapper>
            <div className="loader"></div>
          </LoaderWrapper>
        )}

        <VideoWrapper style={{ display: isLoading ? "none" : "flex" }}>
          <Video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            onCanPlay={handleVideoLoaded}
          >
            <source src={VideoFile} type="video/mp4" />
            <source
              src={VideoFile.replace(".mp4", ".webm")}
              type="video/webm"
            />
            <source src={VideoFile.replace(".mp4", ".ogg")} type="video/ogg" />
            Your browser does not support the video tag.
          </Video>
        </VideoWrapper>

        <HeadingWrapper>
          <MainHeading>
            THE IMMORTAL SOLUTION AND ULTIMATE NECESSITY OF ALL YOUR PROBLEMS.
          </MainHeading>
        </HeadingWrapper>
      </Wrapper>

      <ContentSection>
        <HeaderP className="semiBold" data-aos="zoom-in">
          We offer holistic healing and wellness through the powerful
          combination of Reiki and yoga, helping you achieve balance &
        </HeaderP>
        <HeaderP className="semiBold" data-aos="zoom-in">
          <Highlight>
            <span className="nowrap">MEDICINE-FREE LIFE.</span>
          </Highlight>
        </HeaderP>
      </ContentSection>
    </>
  );
}

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0 5%;
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 80px;
`;

const HeadingWrapper = styled.div`
  position: absolute;
  top: 8.5%;
  width: 100%;
  text-align: center;
  padding: 0 17%;

  @media (max-width: 1024px) {
    top: 8.8%;
  }

  @media (max-width: 768px) {
    top: 10%;
    padding: 0 2%;
  }

  @media (max-width: 480px) {
    top: 8%;
  }
`;

const MainHeading = styled.h1`
  font-size: clamp(1rem, 2vw, 2rem);
  color: white;
  background: rgba(14, 38, 78, 0.7);
  padding: 0.65rem;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
  line-height: 1.2;

  @media (max-width: 1024px) {
    font-size: clamp(1.2rem, 4vw, 2rem);
    padding: 0.5rem;

  @media (max-width: 768px) {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    padding: 0.2rem;
  }

  @media (max-width: 480px) {
    font-size: clamp(1.2rem, 4vw, 2.5rem);
  }
`;
const ContentSection = styled.div`
  text-align: center;
  padding: 2rem 5%;
  background: rgba(12, 7, 65, 0.82);
  border-radius: 0px;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  color: #fff;
  max-width: 100%;
`;

const HeaderP = styled.p`
  font-size: clamp(1.3rem, 2.5vw, 1.8rem);
  color: #fff;
  margin: 0.5rem 0;
`;

const Highlight = styled.span`
  color: #ffeb3b;
  font-weight: bold;
`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

const GlobalStyle = createGlobalStyle`
  .loader {
        transform: rotateZ(45deg);
        perspective: 1000px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        color: #fff;
      }
        .loader:before,
        .loader:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: inherit;
          height: inherit;
          border-radius: 50%;
          transform: rotateX(70deg);
          animation: 1s spin linear infinite;
        }
        .loader:after {
          color: #FF3D00;
          transform: rotateY(70deg);
          animation-delay: .4s;
        }

      @keyframes rotate {
        0% {
          transform: translate(-50%, -50%) rotateZ(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotateZ(360deg);
        }
      }

      @keyframes rotateccw {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(-360deg);
        }
      }

      @keyframes spin {
        0%,
        100% {
          box-shadow: .2em 0px 0 0px currentcolor;
        }
        12% {
          box-shadow: .2em .2em 0 0 currentcolor;
        }
        25% {
          box-shadow: 0 .2em 0 0px currentcolor;
        }
        37% {
          box-shadow: -.2em .2em 0 0 currentcolor;
        }
        50% {
          box-shadow: -.2em 0 0 0 currentcolor;
        }
        62% {
          box-shadow: -.2em -.2em 0 0 currentcolor;
        }
        75% {
          box-shadow: 0px -.2em 0 0 currentcolor;
        }
        87% {
          box-shadow: .2em -.2em 0 0 currentcolor;
        }
      }
   
`;
