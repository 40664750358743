import React, { useState, useEffect } from "react";
import Sidebar from "./components/Nav/Sidebar";
import DesktopNavbar from "./components/Nav/DesktopNavbar";
import TopNavbar from "./components/Nav/TopNavbar";
import { Link, useLocation } from "react-router-dom"; // For navigation links
import whatsapp from "./assets/img/whatsapp-logo.png";

const MainContent = ({ toggleSidebar, closeSidebar, sidebarOpen }) => {
  const location = useLocation();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const excludedRoutes = ["/products/store"];

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {/* Desktop Navbar */}
      {isDesktop && !excludedRoutes.includes(location.pathname) && (
        <DesktopNavbar />
      )}

      {/* Top Navbar */}
      <TopNavbar toggleSidebar={toggleSidebar} />

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />

      {/* Main Navigation Links */}
      <nav className="main-nav">
        <Link to="/home">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/courses">Courses</Link>
        <Link to="/services">Services</Link>
        <Link to="/products">Products</Link>
        <Link to="/contact">Contact</Link>
      </nav>

      {/* WhatsApp Icon */}
      <a
        href="https://wa.me/919909000539"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <img src={whatsapp} alt="WhatsApp" width="50" height="50" />
      </a>
    </div>
  );
};

export default MainContent;
