import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "../Nav/Sidebar"; // Sidebar component is imported
import Backdrop from "../Elements/Backdrop";
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { Link as RouterLink, useLocation } from "react-router-dom";

export default function TopNavbar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      {/* Sidebar imported from the Sidebar component */}
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={handleCloseSidebar} />
      {sidebarOpen && <Backdrop onClick={handleCloseSidebar} />}
      <Wrapper className="flexCenter animate">
        <NavInner className="container flexSpaceBetween">
          <LogoContainer>
            <RouterLink
              to="/"
              style={{ display: "flex", alignItems: "center" }}
              onClick={(e) => {
                if (window.location.pathname === "/") {
                  e.preventDefault();
                  document
                    .getElementById("top")
                    ?.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <LogoIcon />
              <h1
                className="font20 extraBold"
                style={{
                  marginLeft: "10px",
                  lineHeight: "1.4",
                  marginTop: "-15px",
                }}
              >
                Amruttam Reiki Yoga
              </h1>
            </RouterLink>
          </LogoContainer>

          <BurgerWrapper className="pointer" onClick={handleToggleSidebar}>
            <BurgerIcon />
          </BurgerWrapper>

          <NavLinks className="desktopNav">
            <ul className="flex">
              {["Home", "Courses", "Services", "Products", "Contact"].map(
                (item, index) => (
                  <li key={index}>
                    <RouterLink
                      to={`#${item.toLowerCase()}`}
                      className={`navLink ${
                        location.pathname === `#${item.toLowerCase()}`
                          ? "active-link"
                          : ""
                      }`}
                    >
                      {item}
                    </RouterLink>
                  </li>
                )
              )}
            </ul>
          </NavLinks>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 223, 136, 0.15),
      rgba(4, 1, 106, 0.1),
      rgba(255, 255, 255, 0)
    );
    z-index: -1;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    display: flex;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const NavLinks = styled.div`
  display: flex;
  .desktopNav {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin: 0 15px;
      position: relative;
    }

    .navLink {
      text-decoration: none;
      color: #04016a;
      font-weight: bold;
      font-size: 1rem;
      padding: 8px 0;
      &:hover {
        color: #ffeb80;
      }
    }
  }

  @media (max-width: 768px) {
    display: none; /* Hide nav links on small screens */
  }
`;

const BurgerWrapper = styled.button`
  outline: none;
  border: 0;
  background-color: transparent;
  height: 100%;
  padding: 0;
  z-index: 11; /* Ensure burger icon stays above content */

  @media (max-width: 768px) {
    display: block; /* Show burger icon on small screens */
  }

  @media (min-width: 769px) {
    display: none; /* Hide burger icon on larger screens */
  }

  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: ${(props) =>
    props.sidebarOpen ? 0 : 1}; /* Fade out burger icon when sidebar is open */
  transform: ${(props) =>
    props.sidebarOpen
      ? "scale(0)"
      : "scale(1)"}; /* Shrink burger icon when sidebar opens */
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  @media (max-width: 760px) {
    padding: 0 15px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
  }

   @media (min-width: 768px) and (max-width: 1024px) {
    h1 {
      font-size: clamp(19px, 1.3vw, 20px); /* Slightly smaller font size */
    }
`;
