import React from "react";
import styled from "styled-components";
import QuoteIcon from "../../assets/svg/Quotes";

export default function TestimonialBox({ text, author, stars }) {
  return (
    <Wrapper data-aos="zoom-out-up">
      <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper>
      <ScrollableContent>
        <p className="whiteColor font17">{text}</p>
      </ScrollableContent>
      <AuthorWrapper>
        <StarRating stars={stars} />
        <p className="orangeColor font13" style={{ marginLeft: "auto" }}>
          <em>{author}</em>
        </p>
      </AuthorWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 90%; /* Responsive width to adapt on smaller screens */
  max-width: 500px; /* Maximum width for large screens */
  height: 100%; /* Ensure height is consistent */
  margin: 0 auto; /* Centers the box horizontally */
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #04016a;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  aspect-ratio: 1; /* Maintain square aspect ratio */
`;

const QuoteWrapper = styled.div`
  position: relative; /* Changed to relative to respect parent positioning context */
  top: -20px; /* Adjust to position the icon slightly above */
  left: 10px; /* Adjust for left offset */
  z-index: 100; /* Ensure it's above the content */
  width: 40px; /* Set width */
  height: 40px; /* Set height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Transparent background */
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto; /* Make content scrollable */
  margin: 20px 0; /* Ensure content isn't too close to the edges */
  padding-right: 10px;
  display: flex;
  justify-content: center; /* Center the text */
  align-items: flex-start; /* Align text to the top */
  max-height: 100%; /* Ensure the content does not overflow the box */

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Center the author and rating horizontally */
  margin-top: 10px;
  flex-wrap: wrap;
`;

const StarRating = ({ stars }) => (
  <StarWrapper>
    {[...Array(5)].map((_, index) => (
      <Star key={index} filled={index < stars}>
        ★
      </Star>
    ))}
  </StarWrapper>
);

const StarWrapper = styled.div`
  display: flex;
  margin-right: 15px;
`;

const Star = styled.span`
  color: ${({ filled }) => (filled ? "#FFD700" : "#e0e0e0")};
  font-size: 24px;
  margin: 0 2px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;
