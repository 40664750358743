import React, { useEffect } from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import { Link } from "react-router-dom";

import CourseImg1 from "../../assets/img/courses/Course-1.png";
import CourseImg2 from "../../assets/img/courses/Course2.png";
import CourseImg4 from "../../assets/img/courses/Course4.png";
import CourseImg5 from "../../assets/img/courses/Course5.png";
import CourseImg6 from "../../assets/img/courses/Course6.png";
import CourseImg3 from "../../assets/img/courses/Course-3.png";

export default function Courses() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const yOffset = -80;
      const element = document.getElementById(
        window.location.hash.substring(1)
      );
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  return (
    <Wrapper id="courses" data-aos="zoom-in">
      <AnchorOffset />
      <div className="container">
        <HeaderInfo>
          <Title>Our Transformative Courses</Title>
          <Description>
            Our transformative courses at Amruttam Reiki Yoga offer in-depth
            learning in Reiki, meditation, and spiritual practices, empowering
            you to heal and lead a balanced, fulfilling life.
          </Description>
        </HeaderInfo>
        <CourseGrid>
          <CourseBox>
            <Link to="/courses/reiki-course">
              <ProjectBox
                img={CourseImg1}
                title="Reiki Course"
                text="Purify and empower your life energies - Prana, Chaitanya, and Kundalini Shakti with our Reiki attunements."
              />
            </Link>
          </CourseBox>
          <CourseBox>
            <Link to="/courses/reiki-1st-and-2nd-degree">
              <ProjectBox
                img={CourseImg2}
                title="Reiki 1st and 2nd Degree"
                text="Uplift your life Prana & Consciousness with our progressive 1st & 2nd degree Reiki course."
              />
            </Link>
          </CourseBox>
          <CourseBox>
            <Link to="/courses/reiki-3rd-degree">
              <ProjectBox
                img={CourseImg3}
                title="Reiki 3rd Degree"
                text="Initiate your Kundalini Shakti (Serpentine Power) purification & activation with our 3rd degree Reiki course."
              />
            </Link>
          </CourseBox>
          <CourseBox>
            <Link to="/courses/pendulum">
              <ProjectBox
                img={CourseImg4}
                title="Pendulum(Dowsing)"
                text="Know about yourself and for yourself with pendulum power."
              />
            </Link>
          </CourseBox>
          <CourseBox>
            <Link to="/courses/reiki-crystal-basic">
              <ProjectBox
                img={CourseImg5}
                title="Reiki Crystal Basic Course"
                text="Make the positive power of your sadhana thousands of times more effective with crystals."
              />
            </Link>
          </CourseBox>
          <CourseBox>
            <Link to="/courses/reiki-personalized">
              <ProjectBox
                img={CourseImg6}
                title="Reiki Crystal Personalized Course"
                text="Achieve your life goals or purpose by using the right crystal for yourself."
              />
            </Link>
          </CourseBox>
        </CourseGrid>
      </div>
    </Wrapper>
  );
}

// Styled Components

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding-top: 80px;
  scroll-margin-top: 80px;
  margin-bottom: 60px;

  @media (max-width: 960px) {
    padding-top: 60px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    padding-top: 50px;
    margin-bottom: 20px;
  }
`;

const HeaderInfo = styled.div`
  text-align: center;
  padding: 0 20px;

  @media (max-width: 860px) {
    padding: 0 15px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #04016a;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 10px #ffeb80;
  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.9rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #333;
  background: rgba(255, 255, 0, 0.2);
  border-left: 5px solid #04016a;
  padding: 15px;
  border-radius: 5px;
  line-height: 1.6;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 0, 0.4);
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const CourseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  margin-top: 40px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
`;

const CourseBox = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  transition: transform 0.3s ease;

  @media (max-width: 480px) {
    max-width: 320px; /* Shrink the course box on smaller screens */
  }
`;

const AnchorOffset = styled.div`
  content: "";
  display: block;
  height: 80px;
  margin-top: -80px;
`;
