import React, { useEffect } from "react";
import styled from "styled-components";
import mikaoImage from "../../assets/img/Dr. Usui.png";
import Footer from "../Sections/Footer";

const PageWrapper = styled.section`
  max-width: 900px;
  margin: 120px auto;
  margin-bottom: 20px;
  padding: 50px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  line-height: 1.8;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 20px;
    margin: 60px auto;
  }
`;

const ContentSection = styled.div`
  flex: 1;
  padding: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    margin-top: 20px;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  margin-top: 20px;

  img {
    width: 100%;
    max-width: 326px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

const BioDetails = styled.div`
  margin-top: 15px;
  font-size: 1rem;
  color: #555;

  p {
    margin: 5px 0;
  }
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
  margin-top: 40px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BlogHeading = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  margin-top: 40px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: linear-gradient(135deg, #0077cc, #005fa3);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.3rem;
  }
`;

const BlogLink = styled.a`
  display: inline-block;
  font-size: 1.2rem;
  color: #0077cc;
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
  &:hover {
    color: #005fa3;
    text-decoration: underline;
  }
`;

const MikaoUsui = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PageWrapper>
        <ImageWrapper>
          <img src={mikaoImage} alt="Dr. Mikao Usui" />
          <BioDetails>
            <p>
              <strong>Born:</strong> 15 August 1865, Taniai (now called Miyama
              cho), Gifu
            </p>
            <p>
              <strong>Died:</strong> 9 March 1926 (aged 60), Fukuyama
            </p>
            <p>
              <strong>Nationality:</strong> Japanese
            </p>
            <p>
              <strong>Occupation:</strong> Reiki Master
            </p>
            <p>
              <strong>Known for:</strong> Reiki
            </p>
          </BioDetails>
        </ImageWrapper>

        <ContentSection>
          <Heading>Mikao Usui</Heading>
          <Paragraph>
            Mikao Usui was a Japanese spiritual teacher and the founder of
            Reiki, a form of energy healing that has helped countless
            individuals achieve balance and wellness.
          </Paragraph>
          <Paragraph>
            Born in 1865, Usui developed the Reiki healing system after a
            profound spiritual experience. He taught Reiki to over 2,000 people
            during his lifetime, emphasizing healing through spiritual energy.
          </Paragraph>
          <Paragraph>
            His teachings continue to inspire millions around the world,
            emphasizing the importance of self-improvement, personal growth, and
            universal energy healing.
          </Paragraph>

          <SubHeading>Early Life, Family, and Education</SubHeading>
          <Paragraph>
            Mikao Usui was born in 1865 in the Yamagata Prefecture of Japan. He
            came from a relatively humble family background and was raised in a
            time of great social and political change in Japan.
          </Paragraph>
          <Paragraph>
            Usui’s education began in the traditional Japanese way, where he
            studied various classical texts and martial arts. His early
            education focused on the arts, philosophy, and spirituality, which
            played a significant role in shaping his worldview and later
            teachings.
          </Paragraph>
          <Paragraph>
            His deep interest in spiritual growth led him to study Buddhism and
            other Eastern philosophies. Usui became well-versed in both the
            spiritual and physical aspects of healing, which would later
            contribute to the development of Reiki.
          </Paragraph>

          <SubHeading>Career and activities</SubHeading>
          <Paragraph>
            Born in Japan, Usui was a man of diverse interests and pursuits
            throughout his life. He was a deeply spiritual person who studied
            many different disciplines, including medicine, psychology, and
            Buddhist teachings. Usui was an accomplished scholar and
            practitioner, particularly in the areas of martial arts, and he also
            held positions within Japan’s military and government during his
            early career.
          </Paragraph>
          <Paragraph>
            Before discovering Reiki, Usui had a varied career. He worked as a
            teacher and a professor at various institutions, which allowed him
            to study both Western and Eastern philosophies and sciences. His
            experiences in education and his travels across Japan exposed him to
            different healing traditions and spiritual practices, which
            ultimately influenced his development of Reiki. Usui was deeply
            influenced by Buddhism and its teachings, and his spiritual journey
            was critical in shaping the principles that would later form the
            foundation of Reiki healing.
          </Paragraph>
          <Paragraph>
            Usui's breakthrough in the creation of Reiki reportedly occurred
            after a profound spiritual experience. In 1922, he ascended Mount
            Kurama, a sacred mountain in Japan, where he meditated for 21 days,
            fasting and seeking enlightenment. During this time, Usui
            experienced a mystical event that led to his discovery of Reiki, the
            practice of channeling universal life energy to heal and balance the
            body and mind. After his awakening, Usui dedicated his life to
            sharing this healing technique, training students and establishing a
            school in Tokyo, where he taught others to use the healing powers of
            Reiki to treat various physical, mental, and emotional conditions.
          </Paragraph>
          <Paragraph>
            Usui's legacy has persisted through the decades, as Reiki continues
            to be practiced worldwide. His teachings were passed on to his
            students, and the practice has since evolved into multiple styles
            and systems. Though Usui passed away in 1926, his work remains
            influential in the world of energy healing, and his methods have
            inspired millions seeking alternative therapies for healing and
            personal growth.
          </Paragraph>
        </ContentSection>
        <BlogHeading>To see more kind of interesting blogs:</BlogHeading>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2017/12/28/why-reiki-is-easier-and-better-then-other-healing-methods/"
          target="_blank"
        >
          🌟 Why Reiki is easier and better than other healing methods
        </BlogLink>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2017/12/28/the-reasons-for-the-weaknesses-of-our-mental-physical-body-obstacles-faced-throughout-your-lifetime/"
          target="_blank"
        >
          🌟 The Reasons for the weaknesses of our Mental & Physical Body &
          Obstacles faced throughout your lifetime
        </BlogLink>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2006/10/09/knowledge-blooming-process-in-common-man-vs-scientist-and-knowledge-browsing-process-in-human-mind-from-universe-2/"
          target="_blank"
        >
          🌟 Knowledge blooming process in Common Man v/s Scientist and
          Knowledge browsing process in Human Mind from Universe
        </BlogLink>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2006/09/25/software-developers-boost-up-your-efficiency-by-yoga-and-reiki/"
          target="_blank"
        >
          🌟 Software Developers!!! Boost up your Efficiency by Yoga and Reiki
        </BlogLink>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2017/12/28/general-uses-of-reiki/"
          target="_blank"
        >
          🌟 General Uses of Reiki
        </BlogLink>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2017/12/28/a-universal-meaning-of-reiki-is-universal-life-force-rei-is-universal/"
          target="_blank"
        >
          🌟 A Universal meaning of REIKI is Universal Life Force REi – is
          Universal
        </BlogLink>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2011/03/16/give-reiki-to-earth-to-save-earth-for-whatever-happened-in-japan/"
          target="_blank"
        >
          🌟 Give Reiki to Earth to Save Earth for whatever happened in Japan
        </BlogLink>

        <BlogLink
          href="https://bharattkumar.wordpress.com/2006/09/28/energetic-and-chaitasic-development/"
          target="_blank"
        >
          🌟 ENERGETIC and CHAITASIC development
        </BlogLink>
      </PageWrapper>
      <Footer />
    </div>
  );
};

export default MikaoUsui;
