import React, { useEffect, useState } from "react";
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import Courses from "../components/Sections/Courses";
import Services from "../components/Sections/Services";
import Products from "../components/Sections/Products";
import WhyChooseUsSection from "../components/Sections/WhyChooseUsSection";
import AboutMikaoUsui from "../components/Sections/AboutMikaoUsui";
import CelebrationPopup from "../components/Elements/CelebrationPopup";

export default function Landing({ toggleSidebar }) {
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["home", "courses", "services", "products", "contact"];
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const { offsetTop, clientHeight } = element;

          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + clientHeight
          ) {
            setActiveSection(section);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <CelebrationPopup />
      <TopNavbar activeSection={activeSection} toggleSidebar={toggleSidebar} />
      <Header id="home" />
      <AboutMikaoUsui />
      <WhyChooseUsSection />
      <Courses id="courses" />
      <Services id="services" />
      <Products id="products" />
      <Contact id="contact" />
      <Footer />
    </>
  );
}
