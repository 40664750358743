import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, title, text, action }) {
  return (
    <Wrapper>
      <ImgBtn
        className="aniamte pointer"
        onClick={action ? () => action() : null}
      >
        <img className="radius8" src={img} alt="project" />
      </ImgBtn>
      <Title>{title}</Title> {/* Centered title */}
      <Text>{text}</Text> {/* Description text */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center the text */
  margin-top: 30px;

  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 8px; /* Ensures rounded corners */
  }
`;

const ImgBtn = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
  :hover > img {
    opacity: 0.5;
  }
`;

const Title = styled.h3`
  font-size: 1.5rem; /* Default font size */
  font-weight: bold;
  padding-bottom: 10px;
  color: #04016a; /* Optional: You can use the color you need */
  margin: 0;
`;

const Text = styled.p`
  font-size: 1.2rem; /* Text font size */
  color: #383838; /* Optional: Adjust text color */
  line-height: 1.5; /* Line height for readability */
  margin: 0;
`;
