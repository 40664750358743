import React, { useState, useEffect } from "react";
import CelebrationPopupImg from "../../assets/img/Celebration_Popup.png";

const CelebrationPopup = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Show popup every time the page loads or reloads
    setShow(true);
  }, []);

  const closePopup = () => setShow(false);

  const openAppointmentForm = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSf-PrCbHJxNymRlNJSgsiUDYDSDE1OJvumAP-YPFIojLptiZA/viewform?",
      "_blank"
    );
  };

  return (
    <>
      <style>
        {`
          @keyframes popup-enter {
            from {
              opacity: 0;
              transform: scale(0.9);
            }
            to {
              opacity: 1;
              transform: scale(1);
            }
          }

          @keyframes glowing-border {
            0% {
              box-shadow: 0 0 5px #ff8c00, 0 0 15px #ff8c00, 0 0 30px #ff004c, 0 0 45px #ff004c;
            }
            50% {
              box-shadow: 0 0 10px #ff004c, 0 0 20px #ff004c, 0 0 40px #ff8c00, 0 0 60px #ff8c00;
            }
            100% {
              box-shadow: 0 0 5px #ff8c00, 0 0 15px #ff8c00, 0 0 30px #ff004c, 0 0 45px #ff004c;
            }
          }

          @media (max-height: 750px) {
            div.popup-container {
              height: 90%; /* Use 90% of the viewport height */
              overflow-y: auto; /* Make it scrollable */
            }

            div.popup-image-container {
              height: 30%; /* Reduce the height of the image section */
            }

            div.popup-content-container {
              flex: 1; /* Allow the content section to adjust dynamically */
            }
          }

          @media (max-width: 768px) {
            div.popup-container {
              width: 90%;
            }

            h3.popup-heading {
              font-size: 1.4rem;
            }

            p.popup-description {
              font-size: 1rem;
            }

            button.popup-button {
              font-size: 0.9rem;
              padding: 8px 16px;
            }
          }
        `}
      </style>
      {show && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={closePopup}
        >
          <div
            className="popup-container"
            style={{
              background: "#fff",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              maxWidth: "90%",
              width: "600px",
              animation: "popup-enter 0.5s ease",
              position: "relative",
              height: "auto",
              maxHeight: "95%", // Ensure the popup never exceeds viewport height
              overflowY: "auto", // Enable scrolling if content overflows
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close Button */}
            <button
              onClick={closePopup}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                color: "#04016a",
                border: "none",
                fontSize: "1.25rem",
                cursor: "pointer",
                fontWeight: "bold",
                borderRadius: "50%",
              }}
            >
              ✕
            </button>

            {/* Image Section */}
            <div
              className="popup-image-container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                background:
                  "linear-gradient(45deg, rgb(255, 66, 8), rgb(255, 238, 0))",
                borderRadius: "15px 15px 0 0",
                animation: "glowing-border 2s infinite",
                height: "250px", // Default height
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url('${CelebrationPopupImg}')`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: "15px",
                  boxShadow: "inset 0 0 10px rgba(255, 255, 255, 0.5)",
                }}
              ></div>
            </div>

            {/* Content Section */}
            <div
              className="popup-content-container"
              style={{
                padding: "20px",
                textAlign: "center",
                backgroundColor: "#f9f9f9",
              }}
            >
              <h3
                className="popup-heading"
                style={{
                  fontSize: "1.6rem",
                  color: "#04016a",
                  marginBottom: "15px",
                  background:
                    "linear-gradient(90deg, #ff8c00, #ffffff, #28a745)",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                🎉 Celebrating 1 Year of Crystal Museum & Store! 🎉
              </h3>
              <p
                className="popup-description"
                style={{
                  fontSize: "1.2rem",
                  color: "#333",
                  marginBottom: "20px",
                }}
              >
                We are here to solve all your life problems with 26+ years of
                experience. Celebrate with us and start your journey towards
                better solutions!
              </p>
              <button
                className="popup-button"
                onClick={openAppointmentForm}
                style={{
                  background: "linear-gradient(45deg, #ff8c00, #ff004c)",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "25px",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  transition: "transform 0.3s",
                }}
                onMouseOver={(e) => (e.target.style.transform = "scale(1.1)")}
                onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
              >
                Book Your Appointment Now
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CelebrationPopup;
