import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// Import images
import image1 from "../../assets/img/gallery/image1.jpg";
import image2 from "../../assets/img/gallery/image2.jpg";
import image3 from "../../assets/img/gallery/image3.jpg";
import image4 from "../../assets/img/gallery/image4.jpg";
import image5 from "../../assets/img/gallery/image5.jpg";
import image6 from "../../assets/img/gallery/image6.jpg";
import image7 from "../../assets/img/gallery/image7.jpg";
import image8 from "../../assets/img/gallery/image8.jpg";
import image9 from "../../assets/img/gallery/image9.jpg";
import image10 from "../../assets/img/gallery/image10.jpg";
import Footer from "../Sections/Footer";

export default function Gallery() {
  const [isHovered, setIsHovered] = useState(false);
  const sliderRef = useRef(null);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered && sliderRef.current) {
        const slider = sliderRef.current;
        slider.scrollBy({ left: 250, behavior: "smooth" });

        if (slider.scrollLeft >= slider.scrollWidth / 2) {
          slider.scrollLeft = 0;
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [isHovered]);

  return (
    <Wrapper>
      <ContentWrapper>
        {/* Directly render ImageSlider instead of ImageAnimation */}
        <ImageSlider
          ref={sliderRef}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {[...images, ...images].map((src, index) => (
            <ImageItem key={index}>
              <img src={src} alt={`Artwork ${index + 1}`} />
            </ImageItem>
          ))}
        </ImageSlider>
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
}

// Styled-components for styling
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding-top: 100px;
  width: 100%;
  background-color: #f7f7f7;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 40px;
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    padding: 20px 30px;
  }
`;

const ImageSlider = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 0;
  width: 100%;
  height: 600px; /* Default height for larger screens */
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    height: auto; /* Adjust height for smaller screens */
  }
`;

const ImageItem = styled.div`
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* Maintain a 16:9 aspect ratio (or adjust as needed) */
  overflow: hidden;
  scroll-snap-align: start;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Show the entire image without distortion */
    display: block;
  }
`;
