import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TestimonialSlider from "../Elements/TestimonialSlider";
import ARPGImage from "../../assets/img/products/arpg.png";
import AmruttamBatti from "../../assets/img/products/amruttam-batti.jpg";
import Protector from "../../assets/img/products/protector.png";

export default function Products() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Wrapper id="products" data-aos="zoom-in">
      <div className="">
        <div className="container">
          <HeaderInfo>
            <Title>Our Products</Title>
            <Description>
              Explore our selection of high-quality products designed to enhance
              your wellness journey.
            </Description>
          </HeaderInfo>
          <ProductBoxRow>
            {productData.map((product, index) => (
              <ProductBoxWrapper key={index}>
                <Link to={product.link}>
                  <img src={product.image} alt={product.name} />
                  <h3>{product.name}</h3>
                </Link>
                <p>{product.description}</p>
                <LinkButton to={product.link}>Know More</LinkButton>
              </ProductBoxWrapper>
            ))}
          </ProductBoxRow>
          <VisitStore>
            <p>
              To see more products,{" "}
              <Link to="/products/store" rel="noopener noreferrer">
                Visit
              </Link>{" "}
              our store.
            </p>
          </VisitStore>
        </div>
      </div>

      <TestimonialInfo data-aos="zoom-out-down">
        <h1 className="font60 extraBold">What They Say?</h1>
        <p className="font25">
          Explore heartfelt reviews from our students, sharing their
          transformative experiences with Amruttam Reiki Yoga.
        </p>
      </TestimonialInfo>
      <TestimonialSlider />

      {/* New Section for "Check Out More Reviews" */}
      <CheckOutReviews>
        <p>
          ** Read more{" "}
          <ReviewsLink
            href="https://www.google.com/search?q=amruttam+reiki+yoga&oq=am&gs_lcrp=EgZjaHJvbWUqDggBEEUYJxg7GIAEGIoFMgYIABBFGDwyDggBEEUYJxg7GIAEGIoFMhUIAhAuGEMYxwEYsQMY0QMYgAQYigUyBggDEEUYOTIGCAQQRRg7MgYIBRBFGDwyBggGEEUYPDIGCAcQBRhA0gEIMjM0MGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8&lqi=ChNhbXJ1dHRhbSByZWlraSB5b2dhSIajt4eaqoCACForEAAQARACGAAYARgCIhNhbXJ1dHRhbSByZWlraSB5b2dhKggIAhAAEAEQApIBD3JlaWtpX3RoZXJhcGlzdKoBPBABMh8QASIbXV6i4Kl-cfL9fB6esgy1CPpIuCAYjNsfORWOMhcQAiITYW1ydXR0YW0gcmVpa2kgeW9nYQ#lkt=LocalPoiReviews&rlimm=11211224345935106336"
            target="_blank"
            rel="noopener noreferrer"
          >
            Reviews <span>▶</span>
          </ReviewsLink>{" "}
          **
        </p>
      </CheckOutReviews>
    </Wrapper>
  );
}

const CheckOutReviews = styled.div`
  text-align: center;
  margin-top: 40px;
  font-size: 1.2rem;
  color: #34495e;
`;

const ReviewsLink = styled.a`
  color: #e74c3c;
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.4rem;
  display: inline-flex; /* Ensures proper alignment of elements */
  align-items: center; /* Align arrow and text vertically */
  gap: 5px; /* Adds spacing between text and arrow */

  &:hover {
    color: #d35400;
  }

  span {
    font-size: 1.5rem;
    color: #e74c3c;
    transition: color 0.3s ease;
  }

  &:hover span {
    color: #d35400;
  }
`;

const productData = [
  {
    name: "ARPG",
    image: ARPGImage,
    description: "Enhance your life energy & consciousness with ARPG.",
    link: "/products/arpg",
  },
  {
    name: "Amruttam Batti",
    image: AmruttamBatti,
    description:
      "Reiki charged natural incense stick for your sublime atmosphere.",
    link: "/products/amruttam-batti",
  },
  {
    name: "Life Protector",
    image: Protector,
    description:
      "Crystal hangings to protect & enhance your energy & well being.",
    link: "/products/life-protector",
  },
];

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding-top: 80px; /* Ensure spacing to avoid overlap */
  scroll-margin-top: 80px; /* Offset for fixed navbar */
  margin-bottom: 60px; /* Add margin below the header */

  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 80px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin-top: 5px;
    padding-top: 80px;
    margin-bottom: 20px;
  }
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const TestimonialInfo = styled.div`
  text-align: center;
  margin-bottom: 20px;
  margin-top: 100px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #04016a;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 10px #ffeb80;
  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.9rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #34495e;
  padding: 15px;
  border-left: 5px solid #04016a;
  background: rgba(255, 255, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  line-height: 1.6;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const LinkButton = styled(Link)`
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #04016a;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: #60669f 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #515195;
    transform: scale(1.05);
  }
`;

const ProductBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin: 40px 0;

  @media (max-width: 1060px) {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  @media (max-width: 1024px) {
    justify-content: space-around; /* Center two items in the row */
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProductBoxWrapper = styled.div`
  width: 32%; /* Ensure three cards per row on large screens */
  max-width: 320px;
  height: 500px; /* Increased height for larger cards */
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 100%;
    height: 250px; /* Increased image height for better visual balance */
    object-fit: cover;
    border-radius: 10px;
  }

  h3 {
    margin: 10px 0;
    font-size: 1.3em;
  }

  p {
    font-size: 1.1em;
    flex-grow: 1;
  }

  @media (max-width: 1024px) {
    width: 48%;
    height: auto; /* Adjust height dynamically for medium screens */
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto; /* Adjust height dynamically for smaller screens */
  }
`;

const VisitStore = styled.div`
  text-align: center;
  font-size: 1.1rem;
  margin-top: 30px;
  p {
    font-size: 1.1em;
    color: #34495e;
  }

  a {
    color: #e74c3c;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
`;
