// routes.js

import React from "react";
import { Route, Routes } from "react-router-dom";

import Landing from "./screens/Landing";
import Store from "./components/Elements/Store";
import About from "./components/Elements/About";

import Reiki from "./components/courses/Reiki";
import Reiki1stAnd2ndDegree from "./components/courses/Reiki1stAnd2ndDegree";
import Reiki3rdDegree from "./components/courses/Reiki3rdDegree";
import Pendulum from "./components/courses/Pendulum";
import ReikiCrystalBasic from "./components/courses/ReikiCrystalBasic";
import ReikiPersonalized from "./components/courses/ReikiPersonalized";
import ARPG from "./components/products/ARPG";
import AmruttamBatti from "./components/products/AmruttamBatti";
import LifeProtector from "./components/products/LifeProtector";
import AllServices from "./components/services/AllServices";
import Courses from "./components/Sections/Courses";
import Services from "./components/Sections/Services";
import Products from "./components/Sections/Products";
import Contact from "./components/Sections/Contact";
import Gallery from "./components/Elements/Gallery";
import MikaoUsui from "./components/Elements/MikaoUsui";

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/services/all-services" element={<AllServices />} />
      <Route path="/home" element={<Landing />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/services" element={<Services />} />
      <Route path="/products" element={<Products />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/products/store" element={<Store />} />
      <Route path="/about" element={<About />} />
      <Route path="/mikao-usui" element={<MikaoUsui />} />
      <Route path="/about/gallery" element={<Gallery />} />
      <Route path="/courses/reiki-course" element={<Reiki />} />
      <Route
        path="/courses/reiki-1st-and-2nd-degree"
        element={<Reiki1stAnd2ndDegree />}
      />
      <Route path="/courses/reiki-3rd-degree" element={<Reiki3rdDegree />} />
      <Route path="/courses/pendulum" element={<Pendulum />} />
      <Route
        path="/courses/reiki-crystal-basic"
        element={<ReikiCrystalBasic />}
      />
      <Route
        path="/courses/reiki-personalized"
        element={<ReikiPersonalized />}
      />
      <Route path="/products/arpg" element={<ARPG />} />
      <Route path="/products/amruttam-batti" element={<AmruttamBatti />} />
      <Route path="/products/life-protector" element={<LifeProtector />} />
    </Routes>
  );
};

export default RoutesComponent;
