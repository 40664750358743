import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet"; // Correct import for Helmet
import MainContent from "./MainContent";
import RoutesComponent from "./routes"; // Import your routes
import AOSProvider from "./components/Elements/AOSProvider";
import BackToTopButton from "./components/Elements/BackToTopButton";

function App() {
  return (
    <AOSProvider>
      <Router>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        {/* Add the target div for scrolling */}
        <div id="top"></div> {/* This is the target for smooth scrolling */}
        <MainContent />
        <RoutesComponent /> {/* Render routes from the routes file */}
        <BackToTopButton />
      </Router>
    </AOSProvider>
  );
}

export default App;
