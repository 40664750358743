import React from "react";
import styled from "styled-components";
import LogoImg from "../../assets/img/Cover.jpg";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa";
import { scroller } from "react-scroll";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

export default function Footer() {
  const location = useLocation();

  const navigate = useNavigate();

  const handleScrollToSection = (section) => {
    if (location.pathname === "/" && section === "home") {
      // If already on the homepage, scroll to the top
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (location.pathname !== "/" && section === "home") {
      // If on a different route and clicking logo, navigate to home and scroll to top
      navigate("/");
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    } else if (location.pathname === "/") {
      // If on the homepage and clicking other links, scroll smoothly to section
      scroller.scrollTo(section, {
        smooth: true,
        offset: -80,
        duration: 500,
      });
    } else {
      // If on a different route and clicking other sections, navigate to the route
      navigate(`/${section}`);
    }
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="royalBlueBg" style={{ minHeight: "100px" }}>
        <Container>
          <InnerWrapper>
            <LogoAndAddressSection>
              <LogoContainer onClick={() => handleScrollToSection("home")}>
                <Logo src={LogoImg} alt="Logo" />
              </LogoContainer>
              <LogoTextAndAddressWrapper>
                <LogoText>
                  AMRUTTAM RESEARCH INSTITUTE FOR REIKI & YOGA PVT. LTD.
                </LogoText>
                <AddressWrapper>
                  <AddressText>
                    Address: HG2, INTERNATIONAL BUSINESS CENTRE, Dumas Rd,
                    Piplod, Surat, Gujarat 395007
                  </AddressText>
                  <OfficeNumber>
                    Office No:{" "}
                    <HighlightedNumber href="tel:+919909000539">
                      +91 9909000539
                    </HighlightedNumber>
                  </OfficeNumber>
                </AddressWrapper>
              </LogoTextAndAddressWrapper>
            </LogoAndAddressSection>

            <LinksWrapper>
              {["home", "courses", "services", "products", "contact"].map(
                (section) => (
                  <RouterLink
                    key={section}
                    to={
                      location.pathname === "/" ? `#${section}` : `/${section}`
                    }
                    onClick={() => handleScrollToSection(section)}
                  >
                    <FooterLink>
                      {section.charAt(0).toUpperCase() + section.slice(1)}
                    </FooterLink>
                  </RouterLink>
                )
              )}
            </LinksWrapper>

            <SocialMediaWrapper>
              <SocialMediaTitle>Follow Us:</SocialMediaTitle>
              <SocialMediaLinks>
                <SocialLink
                  href="http://Facebook.com/amruttamreikiyoga"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </SocialLink>
                <SocialLink
                  href="https://www.instagram.com/amruttamreikiyogapvtltd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </SocialLink>
                <SocialLink
                  href="https://www.youtube.com/@amruttamreikiyogapvtltd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube />
                </SocialLink>
                <SocialLink
                  href="https://x.com/AmruttamReikiY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </SocialLink>

                <SocialLink
                  href="https://www.pinterest.com/amruttamreikiyoga/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPinterest />
                </SocialLink>
                <SocialLink
                  href="http://Linkedin.com/company/amruttamreikiyoga"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </SocialLink>
              </SocialMediaLinks>
            </SocialMediaWrapper>
          </InnerWrapper>
        </Container>
        <StyleP className="whiteColor font13">
          © {getCurrentYear()} -{" "}
          <span className="whiteColor font13" style={{ marginRight: "5px" }}>
            AMRUTTAM RESEARCH INSTITUTE FOR REIKI & YOGA PVT. LTD.
          </span>
          All Rights Reserved.
        </StyleP>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: #333;
`;

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    padding: 40px 0;
    gap: 60px;
  }
`;

const LogoAndAddressSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    width: auto;
    margin-bottom: 0;
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const LogoTextAndAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;

  @media (min-width: 768px) {
    width: 150px;
  }

  @media (min-width: 1200px) {
    width: 200px;
  }
`;

const LogoText = styled.p`
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 16px;
    text-align: left;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }
`;

const AddressWrapper = styled.div`
  color: whitesmoke;
  margin-top: 10px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const AddressText = styled.p`
  color: whitesmoke;
  margin: 5px 0;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;

const OfficeNumber = styled.p`
  color: whitesmoke;
  margin: 5px 0;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;

const HighlightedNumber = styled.a`
  font-weight: bold;
  color: #ffd700;
  text-decoration: none;

  &:hover {
    color: #ffc107;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;

  @media (min-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    gap: 20px;
  }
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.05rem;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const SocialMediaTitle = styled.h4`
  color: white;
  margin-bottom: 10px;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const SocialMediaLinks = styled.div`
  display: flex;
  gap: 15px; /* Increased gap for better spacing */
  align-items: center; /* Center the icons */
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  margin-top: 20px; /* Space from the above section */
  margin-left: 0; /* Remove any left margin */

  @media (min-width: 768px) {
    align-items: center; /* Ensure it remains centered on larger screens */
    margin-top: 0; /* Adjust as needed for better spacing on larger screens */
  }
`;

const SocialLink = styled.a`
  color: white;
  font-size: 24px; /* Increase the size for better visibility */
  transition: all 0.3s ease-in-out; /* Smooth transition for all properties */
  opacity: 0.8; /* Slightly faded by default */
  transform: scale(1); /* Initial scale (no effect) */

  /* On hover, apply the fade-in and scale effect */
  &:hover {
    color: #ffd700; /* Change color on hover */
    opacity: 1; /* Make fully visible */
    transform: scale(1.2); /* Slightly enlarge the icon */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  /* Optional: On focus for accessibility, same effect as hover */
  &:focus {
    color: #ffd700; /* Focus color */
    opacity: 1;
    transform: scale(1.2);
    outline: none; /* Remove outline to avoid extra border */
  }
`;

const StyleP = styled.p`
  text-align: center;
  color: white;
  padding: 10px;
  font-size: 13px;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 15px;
  }
`;
