import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import Protector from "../../assets/img/products/protector.png";

export default function LifeProtector() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper data-aos="zoom-out-right">
      <ContentWrapper>
        <MainHeading>
          <Title>Life Protector</Title>
          <ProtectorImageStyled src={Protector} alt="LifeProtector" />
        </MainHeading>
        <SecondaryHeading>
          <Paragraph>
            Life Protector is a device designed to protect life across different
            dimensions, offering thousands of positive effects. Life Protector
            is made of clear quartz pencils and rose quartz pencils. The
            fundamental principle behind designing the Life Protector is to
            activate and energize all chakras.
          </Paragraph>

          <SubHeading>
            Life Protector offers around 90 amazing uses to support your
            well-being in so many ways!
          </SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Enhance Immunity and Mental Clarity</strong>: It
                energizes the chakras and indirectly activates all nadi tantra
                energies, enhancing immunity and improving physical and mental
                efficiency, bringing peace and freshness to the mind.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Positive Home Energy</strong>: Fills your home with
                uplifting energy, promoting a harmonious living environment.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Kitchen Harmony</strong>: Maintains a positive
                atmosphere in the kitchen, enhancing your cooking experience.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Provides Positive Energy</strong>: Beneficial for those
                in poor health or nearing the end of life, providing positive
                energy.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Enhances Educational Success</strong>: Enhances students
                concentration and focus, fostering an environment conducive to
                learning and ultimately leading to improved academic
                performance.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Purifies Prenatal Energy</strong>: Ensures the food
                consumed by pregnant women is purified for healthy fetal
                development.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Finds Soulmates</strong>: Increases the likelihood of
                unmarried individuals finding suitable life partners.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Enhances Marital Harmony</strong>: Improves
                relationships between couples, fostering love and understanding.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Suppresses Bad Dreams</strong>: Helps in reducing
                nightmares for peaceful sleep.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Shoe & Storage Area Cleansing</strong>: Dissipates
                negative energy from shoe storage and storerooms.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Travel Safety</strong>: Ensures the safety of passengers
                and vehicles during travel.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Some applications of the Life Protector can be used alone
                without any stones, while others require additional stone
                support.
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Contact Details</SubHeading>
          <ContactBox>
            <ContactItem>
              <a href="tel:+919909000539">📞 Contact: +91 9909000539</a>
            </ContactItem>
            <ContactItem>
              <a
                href="https://maps.app.goo.gl/cxtGGoyumwyHRQkr9"
                target="_blank"
                rel="noopener noreferrer"
              >
                🏢 Address: HG-2, International Business Centre, Piplod, Surat
                395007
              </a>
            </ContactItem>
            <ContactItem>
              <a href="mailto:amruttamreikiyoga@gmail.com">
                📧 Email: amruttamreikiyoga@gmail.com
              </a>
            </ContactItem>
          </ContactBox>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 100px 0 0 0; // Ensure no padding is applied here
  margin: 0; // Remove margin around the wrapper
  width: 100%; // Ensure the wrapper takes full width

  @media (max-width: 768px) {
    padding-top: 60px; /* Reduce padding-top for tablet screens */
  }

  @media (max-width: 480px) {
    padding-top: 60px; /* Further reduce padding-top for small mobile screens */
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px; // You may keep this if you want to constrain the content width
  margin: 0 auto;
  padding: 0 40px; // Remove any extra padding here if present
  text-align: center;
  width: 100%; // Ensure it uses the full available width
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a; /* Background color */
  padding: 15px 20px; /* Padding */
  border-radius: 8px; /* Rounded corners */
  margin: 30px 0 15px; /* Margin */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  /* Responsive styles */
  @media (max-width: 1200px) {
    font-size: 1.8rem; /* Slightly smaller for medium screens */
  }

  @media (max-width: 900px) {
    font-size: 1.6rem; /* Smaller for smaller tablets */
  }

  @media (max-width: 600px) {
    font-size: 1.4rem; /* Smaller for mobile devices */
    padding: 10px 15px; /* Adjust padding */
  }

  @media (max-width: 400px) {
    font-size: 1.2rem; /* Even smaller for very small screens */
    padding: 8px 12px; /* Further adjust padding */
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%; /* Increase width */
  margin: 20px auto; /* Center it */
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start; /* Align bullet and text */
  margin-bottom: 15px; /* Increased spacing */
  line-height: 1.5;
  padding-left: 10px; /* Additional padding for spacing */
  text-align: left;
  font-size: 1.1rem;
  color: #333; /* Darker text color */
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px; /* Space between bullet and text */
`;

const BulletText = styled.span`
  flex: 1; /* Allow the text to take up remaining space */
  background-color: #f9f9f9; /* Light background for list items */
  border-radius: 5px; /* Rounded corners for list items */
  padding: 10px; /* Padding inside list items */
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff; /* Light blue background on hover */
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const ProtectorImageStyled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const ContactBox = styled.div`
  background-color: #f0f8ff;
  border: 1px solid #b0c4de;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const ContactItem = styled.div`
  font-size: 1.1rem;
  margin: 10px 0;
  color: #333;
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-image: linear-gradient(45deg, #a67c00 70%, #04016a 30%);
  color: white;
  padding: 10px 20px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
  transition: transform 0.3s, background-position 1s;

  /* Continuous flowing gradient effect */
  background-size: 400% 400%; /* Large background for animation */
  animation: gradientFlow 2s ease infinite;

  &:hover {
    transform: scale(1.1);
  }

  /* Keyframes to animate the gradient */
  @keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
