import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import CourseImg1 from "../../assets/img/courses/Course-1.png";
import ClientImage1 from "../../assets/img/client/clientImage1.jpeg";
import ClientImage3 from "../../assets/img/client/clientImage2.jpeg";
import ClientImage4 from "../../assets/img/client/clientImage3.jpeg";
import ClientImage5 from "../../assets/img/client/clientImage4.jpeg";
import BackgroundImage from "../../assets/img/bg5.jpeg";

export default function Reiki() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleButtonClick = () => {
    const phoneLink = document.createElement("a");
    phoneLink.href = "tel:+919909000539";
    document.body.appendChild(phoneLink);
    phoneLink.click();
    document.body.removeChild(phoneLink);
  };

  return (
    <Wrapper data-aos="zoom-in">
      <ContentWrapper>
        <MainHeading>
          <Title>Reiki Course</Title>
          <CourseImage1Styled src={CourseImg1} alt="Reiki" />
        </MainHeading>
        <SecondaryHeading>
          <IntroParagraph>
            Before we delve into our courses let’s understand,{" "}
            <HighlightedText>What is Reiki?</HighlightedText>
          </IntroParagraph>

          <Paragraph>
            Reiki is a Japanese healing technique, as the founder of this
            technique, Dr. Mikao Usui was from Japan. If we search on the web,
            we can get plenty of theories & literature on Dr. Mikao Usui, him
            achieving the siddhi of Reiki, the history behind unparalleled
            healing techniques of current time.
          </Paragraph>

          <Paragraph>
            However, here is what we would like to share with our readers. How
            our Founder Director Bharatt Mistry & Director of Training Anjana
            Mistry started this research institute, how the Reiki technique has
            shaped our lives, and some miraculous but absolutely stunning
            theories of Reiki energy based on Energy science.
          </Paragraph>

          <Paragraph>
            In simple terms, ‘Rei’- Universal ‘Ki’- Life force energy.
          </Paragraph>
          <Paragraph>
            In Indian language it is called ‘विश्व व्यापी जीवन शक्ति/ प्राण
            शक्ति, चैतन्य शक्ति & कुंडलनी शक्ति’. In the Scientific language
            it’s called ‘Elementary energy’ & in chinese, it is called Chi, In
            Yogic language Reiki is the combination of 3 main life forces, Prana
            Shakti (Life force energy), Chaitanya shakti (Consciousness),
            Kundalini shakti (Serpentine power), from which the Panchamahabhutas
            (5 great elements) are made, which are- 1. Space 2. Air 3. Fire 4.
            Water 5. Earth As per yog shastra, the entire universe is made of
            these 5 elements. Thus we can say that the entire world is made from
            Reiki.
          </Paragraph>

          <Paragraph>
            We can purify & strengthen all 5 elements & their 3 fundamental
            energies with our Reiki attunement.
          </Paragraph>

          <Paragraph>
            The Reiki attunement developed by Reiki master Bharatt Mistry could
            expand the (nadis) subtle energy channels. In the Reiki 1st & 2nd
            degree attunement itself, it contains the power to heal the entire
            Pran Shakti & Chaitanya Shakti of a person. The 3rd degree Reiki
            attunement contains the power of healing Kundalini Shakti of a
            person. The power to unravel & heal the 3 main life forces of human
            existence, the foundational aspects of life Prana Shakti, Chaitanya
            Shakti & Kundalini Shakti, is smoothly achieved in our Reiki
            attunements. The results achieved by us and our students are a
            testament to our effectiveness.
          </Paragraph>

          <Paragraph>
            The 1st, 2nd, & 3rd degree Reiki attunements developed & researched
            by Reiki master Bharatt Mistry, in themselves are capable of healing
            all life aspects by healing these 3 main forces that creates life.
          </Paragraph>

          <Paragraph>
            Empower yourself with our Reiki courses. Your journey to a
            healthier, happier life starts with one simple step and your
            commitment to self-improvement.
          </Paragraph>

          <Paragraph>
            In addition to our 3rd degree master power course, we also offer
            Crystal Reiki Basic & Crystal Reiki personalized courses. In between
            Reiki 3rd degree & Reiki Crystal courses, we are bringing Pendulum
            dowsing course in the near future. Where you can uncover the
            knowledge to get answers to your life problems & questions. Where
            you will learn the 12 main reasons any negative force is generated
            or enters in our life. Which will make you independent of finding
            answers & solutions to your life, empowering you for greater
            success.
          </Paragraph>

          <Paragraph>
            With over 25 years of continuous experience and extremely dedicated
            Sadhna to Reiki, crystals and energy science, our Founder Director,
            Reiki Master Mr. Bharatt Mistry, has led our Reiki course to its
            highly advanced status in the world. His commitment to personal
            growth and healing has enabled us to deliver impressive results in a
            short time. Our students from around the globe have shared their
            positive experiences and remarkable results.
          </Paragraph>

          <CertificationNote>
            Our Reiki courses culminate in a certification upon completion.
          </CertificationNote>

          <SubHeading>
            The key highlights of our Reiki courses are as under
          </SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Continuous Result Based Research</strong>: Our course
                evolves every day with cutting-edge research in Reiki, crystals,
                and energy science.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Faster Results, Deeper Healing</strong>: Experience
                faster energy shifts and deeper healing with advanced attunement
                techniques.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Remove Energy Barriers</strong>: Learn to understand and
                dissolve the energy constitutions of negative karma.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Comprehensive Course</strong>: Our in-depth program
                explores every aspect of life, empowering you with knowledge and
                tools for balanced growth.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Guidance for Success</strong>: Gain insights to navigate
                your journey toward a successful and virtuous life.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Global Success Stories</strong>: Students worldwide have
                experienced rapid, life-changing transformations through our
                Reiki course.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>25 Years of Mastery</strong>: Decades of dedicated
                Sadhana and Research by Reiki Master Bharatt Mistry have led to
                one of the world’s most advanced Reiki courses.
              </BulletText>
            </BulletItem>
          </BulletList>
        </SecondaryHeading>
        {/* Insert Testimonials Component */}
        <Testimonials />
        <section
          className="testimonial-section"
          aria-labelledby="testimonials-heading"
        >
          <h2 id="testimonial-heading">Hear What Our Clients Have to Say</h2>
          <div className="testimonial-content">
            <article className="testimonial-text">
              <img
                src={ClientImage4}
                alt="Dr. Kinjal Desai"
                className="testimonial-image"
                loading="lazy"
              />
              <p>
                I had a wonderful experience at Amruttam Reiki Yoga. Their
                products or rewards actually helps. I am happy that I joined
                this course. I have a lot to learn yet, but my spritual journey
                got stronger and I learned to connect with myself. I got
                intuned. External validation or show off doesn't not affect me.
                I am able to understand things to deeper level. I learnt, your
                external situations are reflections of your inner situation.
              </p>

              <div className="testimonial-author-container">
                <span className="testimonial-author">Dr. Kinjal Desai</span>,
                Physiotherapist
              </div>

              <div className="center-container">
                <button className="demo-button" onClick={handleButtonClick}>
                  Schedule a Meeting
                </button>
              </div>
            </article>
            <div className="testimonial-video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/52o5wRRlETI?si=rmBojEepDuiRmzj9"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                loading="lazy"
                allowFullScreen
                aria-label="Testimonial video"
              ></iframe>
            </div>
          </div>
        </section>
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
}

const Testimonials = () => (
  <TestimonialsSection>
    <SectionTitle>What Our Clients Say About Courses</SectionTitle>
    <TestimonialCard>
      <TestimonialImage src={ClientImage1} alt="Client 1" />
      <TestimonialContent>
        <TestimonialText>
          “You will find a lot of Reiki classes world wide but nothing at par
          with Amruttam research institute for Reiki and yoga. Bharat sir has
          power unparalleled and Anjani madam teaches with a passion and
          knowledge that is unmatched. The Reiki/ energy / karma and chakra
          guidance is very very different and powerful. I have learned Reiki
          with other masters and the power you get in Amruttam research
          institute for Reiki and yoga is much more powerful and the practicals
          teach you in real time how to take power and solve different problems
          in your life. The guru's are not only your guru's but become a part of
          your family who genuinely care for you and always ready to guide you
          in the right direction. As far as you follow the directions and
          guidance, life will definitely take a lovely turn for you and your
          family. I highly recommend these Reiki classes.”
        </TestimonialText>
        <ClientName>Neelu Rajpal Gupta,</ClientName>
        <ClientPosition>Housewife</ClientPosition>
      </TestimonialContent>
    </TestimonialCard>

    <TestimonialCard>
      <TestimonialImage src={ClientImage5} alt="Client 2" />
      <TestimonialContent>
        <TestimonialText>
          “Hello I am Esha Shah Modi, PhD. I am a Material scientist. I have
          done first, second, third , pendulum and crystal level Reiki courses
          here. The spiritual journey and upliftment I have experienced is
          invaluable. As a scientist, I have comprehended each level of the
          course with a practical and scientific approach and have found it to
          be firmly grounded in proper scientific principles and with 100%
          practical applicability. Theoretical understanding provided at each
          level of the course is intense and enlightening. At the same time the
          practicals taught are easy to practice in day-to-day life. Best part
          is the life time free genuine and personal guidance from the Reiki
          Masters. Once a student here, always an inseparable member of the
          Amruttam family.”
        </TestimonialText>
        <ClientName>Dr. Esha Shah Modi,</ClientName>
        <ClientPosition>Material Scientist</ClientPosition>
      </TestimonialContent>
    </TestimonialCard>

    <TestimonialCard>
      <TestimonialImage src={ClientImage3} alt="Client 3" />
      <TestimonialContent>
        <TestimonialText>
          “I got a refrence from one of my relative about this institute and I
          wanted to learn reiki and healing practices. I can say it was my best
          decision to connect with them and started learning about reiki and
          spiritual knowledge. I can say its the best intuition to learn reiki
          and It changed my course of life. I am using their products like
          Amrutam Reiki powered gloubles and Amrutam batti since 2 years and it
          gave me extra ordinary results.”
        </TestimonialText>
        <ClientName>Adit Pariawala,</ClientName>
        <ClientPosition>Fashion Designer</ClientPosition>
      </TestimonialContent>
    </TestimonialCard>
  </TestimonialsSection>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 100px 0 0 0; // Ensure no padding is applied here
  margin: 0; // Remove margin around the wrapper
  width: 100%; // Ensure the wrapper takes full width

  @media (max-width: 768px) {
    padding-top: 60px; /* Reduce padding-top for tablet screens */
  }

  @media (max-width: 480px) {
    padding-top: 60px; /* Further reduce padding-top for small mobile screens */
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px; // You may keep this if you want to constrain the content width
  margin: 0 auto;
  padding: 0 40px; // Remove any extra padding here if present
  text-align: center;
  width: 100%; // Ensure it uses the full available width
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a; /* Background color */
  padding: 15px 20px; /* Padding */
  border-radius: 8px; /* Rounded corners */
  margin: 30px 0 15px; /* Margin */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  /* Responsive styles */
  @media (max-width: 1200px) {
    font-size: 1.8rem; /* Slightly smaller for medium screens */
  }

  @media (max-width: 900px) {
    font-size: 1.6rem; /* Smaller for smaller tablets */
  }

  @media (max-width: 600px) {
    font-size: 1.4rem; /* Smaller for mobile devices */
    padding: 10px 15px; /* Adjust padding */
  }

  @media (max-width: 400px) {
    font-size: 1.2rem; /* Even smaller for very small screens */
    padding: 8px 12px; /* Further adjust padding */
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const CourseImage1Styled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%; /* Increase width */
  margin: 20px auto; /* Center it */
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start; /* Align bullet and text */
  margin-bottom: 15px; /* Increased spacing */
  line-height: 1.5;
  padding-left: 10px; /* Additional padding for spacing */
  text-align: left;
  font-size: 1.1rem;
  color: #333; /* Darker text color */
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px; /* Space between bullet and text */
`;

const BulletText = styled.span`
  flex: 1; /* Allow the text to take up remaining space */
  background-color: #f9f9f9; /* Light background for list items */
  border-radius: 5px; /* Rounded corners for list items */
  padding: 10px; /* Padding inside list items */
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff; /* Light blue background on hover */
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const CertificationNote = styled.p`
  font-weight: bold;
  color: #000;
  margin: 20px 0;
  font-size: 1.2rem;
`;

const IntroParagraph = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6;
`;

const HighlightedText = styled.span`
  font-weight: bold;
  font-size: 1.8rem;
  color: #ff5722; /* Eye-catching color for emphasis */
  display: block;
  margin-top: 10px;
`;

const TestimonialsSection = styled.section`
  width: 100%;
  min-height: 100vh; /* Minimum full viewport height */
  padding: 40px 20px; /* Add padding to ensure proper spacing */
  background-image: url(${BackgroundImage}); /* Add your background image here */
  background-size: cover; /* Ensure the background image covers the entire section */
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* White text for contrast */
  box-sizing: border-box; /* Include padding in height calculation */
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const SectionTitle = styled.h2`
  color: #1e3c61;
  font-size: 2.5rem; /* Adjust font size */
  font-weight: bold;
  margin-bottom: 30px; /* Add spacing below the title */
  text-align: center;
  letter-spacing: 1.5px; /* Optional for styling */

  @media (max-width: 768px) {
    font-size: 2rem; /* Smaller font on mobile */
    margin-bottom: 20px;
  }
`;

const TestimonialCard = styled.div`
  max-width: 1200px; /* Restrict max width for content */
  width: 90%; /* Make it responsive for smaller screens */
  margin: 20px auto;
  background: #04016a; /* Background color for the card */
  border-radius: 15px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack the content vertically */
    padding: 20px 10px;
  }
`;

const TestimonialImage = styled.img`
  width: 100px; /* Adjust size of the testimonial image */
  height: 100px;
  border-radius: 50%; /* Circular image */
  object-fit: cover;
  border: 3px solid #fff; /* White border */
  margin-bottom: 20px; /* Add margin for spacing in mobile view */

  @media (max-width: 768px) {
    width: 80px; /* Smaller image size on mobile */
    height: 80px;
  }
`;

const TestimonialContent = styled.div`
  flex: 1;
  text-align: center; /* Center text on mobile */

  @media (max-width: 768px) {
    text-align: center; /* Left-align text in mobile view */
  }
`;

const TestimonialText = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 30px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller text on mobile */
    margin-bottom: 20px;
  }
`;

const ClientName = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  color: rgb(235, 213, 16); /* Color for the client's name */
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller font size on mobile */
    text-align: center;
  }
`;

const ClientPosition = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  color: rgb(216, 197, 28); /* Different color for the client's position */
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller font size on mobile */
    text-align: center;
  }
`;
