// indexData.js
export const indexData = {
  firstSecondDegreeTheory: [
    { srNo: 1, subject: "Introduction to Reiki" },
    {
      srNo: 2,
      subject:
        "Fundamental reasons for mental and physical illnesses and failure to achieve goals.",
    },
    { srNo: 3, subject: "Basic information about the Chakras in the body." },
    {
      srNo: 4,
      subject:
        "The simplicity and excellence of Reiki compared to other methods.",
    },
    { srNo: 5, subject: "Common Uses of Reiki" },
    { srNo: 6, subject: "Reiki's Five Fundamental Principles." },
    { srNo: 7, subject: "The impact of Reiki and its methodology." },
    { srNo: 8, subject: "Preparation and Eligibility for Learning Reiki" },
    {
      srNo: 9,
      subject:
        "For those who are eager to learn Reiki but are unable to do so.",
    },
    { srNo: 10, subject: "Some misconceptions about Reiki." },
    { srNo: 11, subject: "Questions and Answers" },
    { srNo: 12, subject: "Rules of the Reiki Graduation Course" },
    {
      srNo: 13,
      subject: "Things to keep in mind while taking and giving Reiki",
    },
    {
      srNo: 14.1,
      subject:
        "Common types of aggravation, symptoms of common impurity release",
    },
    { srNo: 14.2, subject: "Symptoms of Acute Impurity Release" },
    {
      srNo: 15,
      subject:
        "Things to keep in mind while taking Reiki and making resolutions",
    },
    {
      srNo: 16,
      subject:
        "Certain things not to do while receiving or giving Reiki or making a resolution",
    },
    { srNo: 17, subject: "Simple Remedies to Get Amazing Reiki Results" },
    { srNo: 18, subject: "Principles of Life Development" },
    { srNo: 19, subject: "The Five Strengths of Man" },
    { srNo: 20, subject: "Main advantages and uses of Yantra" },
    { srNo: 21, subject: "Mindset to Enhance Effectiveness of Reiki" },
    {
      srNo: 22,
      subject: "Seven main qualities for self-assimilation with work",
    },
    {
      srNo: 23,
      subject: "Five main qualities for achieving success in any task or work",
    },
    {
      srNo: 24,
      subject: "Information for getting the best and fastest results in Reiki",
    },
    { srNo: 25, subject: "Three principles of general problem solving" },
    { srNo: 26, subject: "Second degree symbols" },
    {
      srNo: 26,
      subject: "Considerations to keep in mind while creating a symbol",
    },
    { srNo: 26, subject: "Directional symbols of second degree" },
    {
      srNo: 27,
      subject: "Understanding of Reiki and achieving success in life",
    },
    { srNo: 28, subject: "For Great Success" },
    {
      srNo: 29,
      subject:
        "The five principles for one's own and one's child's ultimate development.",
    },
    {
      srNo: 30,
      subject:
        "Golden rules for transforming ordinary personality into great personality.",
    },
    {
      srNo: 31,
      subject:
        "Guidelines for the development of a powerful and prosperous personality.",
    },
    { srNo: 32, subject: "Four Principles of Reiki's Extreme Effectiveness." },
    { srNo: 33, subject: "Fundamental Principles of Energy Flow." },
    {
      srNo: 34,
      subject:
        "Read once in the morning, afternoon, and evening to alleviate internal weaknesses.",
    },
    {
      srNo: 35,
      subject:
        "The ten basic obstacles that prevent a determined individual from being productive.",
    },
    { srNo: 36, subject: "Development of self-confidence" },
    {
      srNo: 37,
      subject:
        "Chakras, Disease related to chakras and its points for treatment",
    },
  ],

  firstDegreePracticals: [
    { srNo: "*", subject: "Necessity to do Gratitude process" },
    {
      srNo: "*",
      subject: "Closing Gratitude Process for continuation of Reiki Action",
    },
    { srNo: "*", subject: "Practical for development of Anahat Chakra" },
    { srNo: 1, subject: "Points of destroying negative energy from Aura" },
    { srNo: 2, subject: "World Welfare" },
    { srNo: 3, subject: "Whole existence shielding of self" },
    { srNo: "*", subject: "Method to fill spinal cord with Reiki energy" },
    { srNo: 4, subject: "Positive and negative energy balancing" },
    { srNo: 5, subject: "Universal Knowledge, Power and Vision of Wisdom" },
    { srNo: 6, subject: "Vision of soul" },
    { srNo: 7, subject: "Activation of Manipur chakra" },
    { srNo: 8, subject: "Balancing of chakras" },
    { srNo: 9, subject: "Shield" },
    {
      srNo: 10,
      subject:
        "Method of taking Reiki on Six chakras of body and other Twenty-eight points",
    },
    { srNo: 11, subject: "Group Treatment" },
    { srNo: 12, subject: "Marathon Method" },
    { srNo: 13, subject: "Aura Operation" },
    { srNo: 13.1, subject: "Aura Operation of others" },
    { srNo: 14, subject: "Spinal cord activation" },
    { srNo: 15, subject: "Purification of five elements" },
    { srNo: 16, subject: "Mahakaal Shield" },
    { srNo: 17, subject: "Special practicals for balancing power of body" },
    {
      srNo: 18,
      subject:
        "Balancing one’s complete activity, efficiency and functionality",
    },
    { srNo: 19, subject: "Balancing of mind, brain and nervous system" },
    {
      srNo: 20,
      subject:
        "Practical for integrating five basic principles of the Reiki in life",
    },
  ],

  secondDegreePracticals: [
    { srNo: "*", subject: "Name and identity of all types of symbols." },
    { srNo: "*", subject: "Primary information of Second Degree" },
    { srNo: "*", subject: "Gratitude procedure for second degree" },
    {
      srNo: "*",
      subject: "Gratitude ritual to be performed once in 24 hours.",
    },
    {
      srNo: "*",
      subject:
        "Small gratitude procedure to be performed after doing 24 hours' gratitude procedure",
    },
    {
      srNo: "*",
      subject:
        "Ending Gratitude Procedure and Ending gratitude procedure for continuation of ongoing practical",
    },
    { srNo: 1, subject: "Method to take Reiki with more power" },
    { srNo: 2, subject: "Method to send Reiki to a non-present person" },
    { srNo: 2.1, subject: "Simple method to send Reiki to non-present person" },
    {
      srNo: 3,
      subject: "Method to take power from banyan tree by means of Reiki",
    },
    {
      srNo: 4,
      subject:
        "Method to take power from holy basil (Tulsi) plant by means of Reiki",
    },
    { srNo: 5, subject: "Method of achieving perfected symbols" },
    { srNo: 6, subject: "Taking Reiki by Color Method" },
    { srNo: 7, subject: "Meditation" },
    { srNo: 8, subject: "Navel Purification" },
    { srNo: 9, subject: "Vishuddh Chakra Purification" },
    { srNo: 10, subject: "Aagya Chakra Purification" },
    { srNo: 11, subject: "Reiki Water" },
    { srNo: 12.1, subject: "Shielding oneself in less time" },
    { srNo: 12.2, subject: "Shielding others in less time" },
    { srNo: 13, subject: "Other uses of Reiki" },
    { srNo: 14, subject: "Giving Reiki to a group" },
    { srNo: 15, subject: "Room charge" },
    { srNo: 16, subject: "Five Elements Great Shielding" },
    { srNo: 17.1, subject: "Reiki Sheet" },
    { srNo: 17.2, subject: "Reiki Curtain" },
    { srNo: 18, subject: "Activation of Hriday Chakra" },
    { srNo: 19, subject: "Activation of Hara Chakra" },
    { srNo: 20, subject: "Activation of Spinal Cord" },
    { srNo: 21, subject: "Activation of Aagya Chakra" },
    { srNo: 22, subject: "Method for giving Reiki to non-living objects" },
    {
      srNo: 23,
      subject:
        "Method of converting each and every kind of negative energy into positive energy",
    },
    { srNo: 24, subject: "Reiki Box" },
    { srNo: 25, subject: "Five Elements Purification" },
    { srNo: 26, subject: "Five Elements Shielding" },
    { srNo: 27, subject: "Importance of Vishuddh Chakra and its purification" },
    { srNo: 28, subject: "Importance of Spinal cord and its purification" },
    {
      srNo: 29,
      subject:
        "Importance of centers from knees till heels of legs and their purification",
    },
    {
      srNo: 30,
      subject: "Activation of spinal cord and its nerves / channels",
    },
    {
      srNo: 31,
      subject: "Purification of Vishuddh Chakra and its nervous system",
    },
    {
      srNo: 32,
      subject: "Purification of Hriday Chakra and it’s nervous system",
    },
    {
      srNo: 33,
      subject: "Purification of Manipur Chakra and its nervous system",
    },
    { srNo: 34, subject: "Purification of Hara Chakra and its nervous system" },
    {
      srNo: 35,
      subject: "Purification of Muladhar Chakra and its nervous system",
    },
    {
      srNo: 36,
      subject: "Purification of Aagya Chakra and its nervous system",
    },
    { srNo: 37, subject: "Purification of consciousness" },
    { srNo: 38, subject: "Purification of knowledge (Gyaan)" },
    { srNo: 39, subject: "Purification of prana or breath" },
    { srNo: 40, subject: "Purification of bone and bone marrow" },
    {
      srNo: 41,
      subject:
        "Purification of digestive system, nervous system, and infinite systems",
    },
    {
      srNo: 42,
      subject:
        "Purification of prana sharir, shukshma sharir, karan sharir, mahakaran sharir, chaitanya sharir, virat sharir and 8 to 14 sharir",
    },
    { srNo: 43, subject: "Purification of eyes, ophthalmic nerves and vision" },
    {
      srNo: 44,
      subject:
        "Purification of universal knowledge, self knowledge, worldly knowledge and knowledge of time",
    },
    { srNo: 45, subject: "Aatmasth" },
    { srNo: 46, subject: "Practical to develop Aagya Chakra" },
    { srNo: 47, subject: "Monk Reiki" },
    {
      srNo: 48.1,
      subject: "Practical for eliminating various adverse planetary effects",
    },
    { srNo: 48.2, subject: "Shield for not going inauspicious places" },
    {
      srNo: 48.3,
      subject:
        "To escape from contact of malefic people and for inauspicious events not to occur",
    },
    {
      srNo: 48.4,
      subject:
        "For annihilation of the effects of our own inauspicious thoughts and inauspicious events",
    },
    {
      srNo: 48.5,
      subject: "For each task in each and every moment to happen auspiciously",
    },
    {
      srNo: 48.6,
      subject:
        "Method of destroying the negative influence of negative energy people, animals, birds, plants or vegetation and places that surrounds us",
    },
    { srNo: 48.7, subject: "Practical to always stay in positive energy" },
    {
      srNo: 48.8,
      subject:
        "Remedies for others thoughts to not get dominant on our thoughts and method for destroying infinite types of mental obstacles in achieving your goal",
    },
    {
      srNo: 49,
      subject:
        "Main great practicals for destroying and not allowing to raise the influence of others mind",
    },
    { srNo: 50, subject: "For keeping good will awakened in women" },
    { srNo: 50.1, subject: "For keeping goodwill awakened in another person" },
    { srNo: 51, subject: "Method to destroy any kind of negative energy" },
    { srNo: 51.1, subject: "Practical to keep Aagya Chakra positive" },
    { srNo: 51.2, subject: "Practical to keep Vishuddh Chakra positive" },
    { srNo: 51.3, subject: "Practical to keep Hriday Chakra positive" },
    {
      srNo: 52,
      subject: "Special Practicals of Reiki: Karma/Deeds destroying",
    },
    {
      srNo: 52.1,
      subject: "Process for destroying very inappropriate karma / deeds",
    },
    {
      srNo: 52.2,
      subject:
        "Process to for not being able to commit condemnable deeds/ karma or to destroy it",
    },
    { srNo: 52.3, subject: "Process for doing Brahma karma" },
    { srNo: 52.4, subject: "Process for doing Gyan / Knowledge deeds/ karma" },
    { srNo: 52.5, subject: "Process for prevention of unnatural death" },
    { srNo: 53, subject: "Points for increasing resistive power" },
    { srNo: 54, subject: "Practical to become stable in self" },
    { srNo: 55, subject: "24 worldly and divine resolutions" },
    { srNo: 56, subject: "Method of filling your whole existence with Reiki" },
    { srNo: 57, subject: "Method to activate spinal cord" },
    { srNo: 58, subject: "Whole existence shield of self" },
    { srNo: 59, subject: "Whole existence shield of others" },
    {
      srNo: 60,
      subject: "Practical for developing precise intuition to win life",
    },
    { srNo: 61, subject: "Presiding deity vision" },
    { srNo: 62, subject: "Activation of Vishuddh Chakra" },
    { srNo: 63, subject: "Practical for development of Anahat Chakra" },
    { srNo: 64, subject: "Skeletal Shield / asthi pinjar shield" },
    {
      srNo: 65,
      subject: "Practical to cultivate five basic principles of Reiki in life",
    },
    { srNo: 66, subject: "Method of giving Reiki to Intention" },
    { srNo: 67, subject: "Tratak" },
    {
      srNo: 68,
      subject:
        "Seven practicals to avoid novel corona (COVID-19) of 1st and 2nd degree",
    },
  ],
};
