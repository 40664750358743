import * as React from "react";
import Companylogo from "../../assets/img/logo.svg";
import styled from "styled-components";

const ResponsiveLogo = styled.img`
  max-width: 100%; /* Scales logo down to fit container width */
  height: auto; /* Keeps the aspect ratio intact */

  /* Default max-height for larger screens */
  max-height: 60px; /* Larger logo for bigger screens */
  margin-bottom: 10px; /* Default margin for larger screens */

  /* Adjust max-height and margin-bottom for medium screens (tablets) */
  @media (max-width: 1024px) {
    max-height: 50px; /* Slightly smaller logo for tablets */
    margin-top: 10px;
  }

  /* Adjust max-height and margin-bottom for smaller screens (mobile) */
  @media (max-width: 768px) {
    margin-top: -5px; /* Larger margin to give more space for mobile */
    max-height: 55px; /* A bit smaller for mobile */
    margin-bottom: 10px; /* Larger margin to give more space for mobile */
  }

  /* Even smaller logo for very small screens */
  @media (max-width: 480px) {
    max-height: 45px; /* Smaller logo for very small screens */
    margin-bottom: 15px; /* Larger margin for very small screens */
    margin-top: -5px; /* Larger margin for very small screens */
  }
`;

function SvgComponent(props) {
  return <ResponsiveLogo src={Companylogo} alt="Company Logo" />;
}

export default SvgComponent;
