export const indexDataFor3rdDegree = {
  thirdDegreeTheory: [
    { srNo: 1, subject: "Awakening Of Amrut Power" },
    { srNo: 2, subject: "The Effortless Or Zero Effort State Of The Mind" },
    { srNo: 3, subject: "7 Mental Attitudes" },
    {
      srNo: 4,
      subject:
        "The Nine Fundamental Principles Of Prosperity According To The Principles Of Reiki Energy",
    },
    { srNo: 5, subject: "Mindset For Unparalleled Success In Reiki Practice" },
    {
      srNo: 6,
      subject:
        "The Reason For Increased Struggle And Delayed Results Of A Reiki Practitioner",
    },
    {
      srNo: 7,
      subject:
        "The Precise Mindset That A Reiki Practitioner, Devotee, And Pious Should Maintain",
    },
    { srNo: 8, subject: "Purification Of The Manomaya Kosha" },
    { srNo: 9, subject: "Purification Of The Pranamaya Kosha" },
    { srNo: 10, subject: "Five Key Principles To Develop One's Eligibility" },
    { srNo: 11, subject: "Names And Identification Of Each Type Of Symbol" },
    { srNo: 12, subject: "Symbols Of Third Degree" },
    { srNo: 13, subject: "Things To Keep In Mind While Creating Symbols" },
    { srNo: 14, subject: "Directional Symbol Of Third Degree" },
    { srNo: 15, subject: "Small Gratitude Procedure" },
    {
      srNo: 16,
      subject: "Small Gratitude Practice Once Every Twenty Four Hours",
    },
    {
      srNo: 17,
      subject:
        "A Small Gratitude Practice After Performing A Gratitude Procedure Once In 24 Hours",
    },
    { srNo: 18, subject: "A Special Small Gratitude Practice" },
    { srNo: 19, subject: "Reiki Cycle" },
    { srNo: 20, subject: "Sixteen Colour Reiki Cycle" },
    { srNo: 21, subject: "Closing Procedure" },
    { srNo: 22, subject: "Closing Process To Continue The Reiki Process" },
    {
      srNo: 23,
      subject: "A Brief Closing Procedure To Continue The Reiki Process",
    },
    {
      srNo: 24,
      subject:
        "Condition For Making Foot Sole Chakra - Earth Connection Chakra And All The Chakras Beneath Those Positive",
    },
    {
      srNo: 25,
      subject: "21 Remedies To Keep Ajna Chakra Positive: Only Topic Names",
    },
    { srNo: 26, subject: "Speciality Of Third Degree" },
    {
      srNo: 27,
      subject: "Points To Keep In Mind While Using The Third Degree",
    },
    {
      srNo: 28,
      subject:
        "Changes To Be Made In Mental Attitude After Learning The Third Degree",
    },
    {
      srNo: 29,
      subject: "Regularities To Be Maintained After Learning The Third Degree",
    },
  ],

  thirdDegreePracticals: [
    { srNo: 1, subject: "Method Of Receiving Reiki By Master Power" },
    {
      srNo: 2,
      subject:
        "Method Of Giving Reiki To An Absent Person Through Master Power",
    },
    {
      srNo: 3,
      subject: "Method Of Giving Sixteen Colour Reiki To An Absent Person",
    },
    { srNo: 4, subject: "Five Elements House Charging By Master Power" },
    { srNo: 5, subject: "House Charging By Sixteen Colour" },
    { srNo: 6, subject: "Reiki Water" },
    { srNo: 7, subject: "Reiki Water By Sixteen Colour" },
    {
      srNo: 7.1,
      subject:
        "Special Practicals And Remedies Of Sixteen Colour Reiki Water (25 Sub Practicals)",
    },
    { srNo: 8, subject: "Development Of Ajna Chakra And Self-Power" },
    { srNo: 9, subject: "Shielding Of Entire Existence By Master Power" },
    { srNo: 10, subject: "Golden Shielding By Master Power" },
    { srNo: 11, subject: "Sixteen Colour Shielding By Master Power" },
    { srNo: 12, subject: "Aura Body Cleansing" },
    {
      srNo: 13,
      subject: "Shielding Of One’s Entire Existence From Others Thoughts",
    },
    { srNo: 14, subject: "Purification Of Spinal Cord And Collar Bones" },
    { srNo: 15, subject: "Protection Of Anahat Chakra" },
    { srNo: 16, subject: "Infinite Purity" },
    { srNo: 17, subject: "Development Of Anahat Chakra" },
    { srNo: 18, subject: "Way To Attain God’s Grace" },
    { srNo: 19, subject: "Shield Of Five Elements And Five Pranas" },
    { srNo: 20, subject: "Blasting Of Negative Energy" },
    { srNo: 21, subject: "Achievements In Work" },
    {
      srNo: 22,
      subject:
        "Asti Pinjar Shield (Asti Pinjar: It Is Rib Cage Collar Bone, Bones Of Hand, Vertebrates Connected To Ribs) Skeletal Shield",
    },
    { srNo: 23, subject: "Spinal Cord Activation" },
    { srNo: 24, subject: "Power Purification" },
    { srNo: 25, subject: "Practical To Attain Steady Wisdom" },
    {
      srNo: 26,
      subject: "Method Of Using Symbols To Destroy Any Type Of Negative Energy",
    },
    { srNo: 27, subject: "Success In Reiki" },
    { srNo: 28, subject: "Achievement Of Life Goals" },
    { srNo: 29, subject: "Attainment Of Knowledge Of Karma" },
    { srNo: 30, subject: "To Generate Enthusiasm" },
    {
      srNo: 31,
      subject: "Method Of Giving Reiki To Intentions With Sixteen Colors",
    },
    { srNo: 32, subject: "Inspection Of Anahat Chakra" },
    {
      srNo: 33,
      subject: "Practicals For Destroying Tantra-Mantras (8 Sub Practicals)",
    },
    { srNo: 34, subject: "Reiki Box" },
    {
      srNo: 35,
      subject:
        "Points To Enhance Resistance Power (Immunity) Through Master Power",
    },
    { srNo: 36, subject: "Reiki Ball" },
    { srNo: 37, subject: "Method Of Destroying Covering Layers Over The Soul" },
    {
      srNo: 38,
      subject: "Method To Destroy Tantric Practices And Killing Practices",
    },
    { srNo: 39, subject: "Tratak (2 Sub Practicals)" },
    {
      srNo: 40,
      subject: "Development Of Anahat Chakra For Knowledge Attainment",
    },
    {
      srNo: 41,
      subject:
        "Method Of Taking Reiki In Sahastrar Chakra By Sixteen Colour Master Power",
    },
    {
      srNo: 42,
      subject:
        "Method Of Taking Reiki In Entire Existence By Sixteen Colour Master Power",
    },
    {
      srNo: 43,
      subject:
        "Method Of Taking Reiki In Spinal Cord By Sixteen Colour Master Power",
    },
    {
      srNo: 44,
      subject:
        "Method Of Taking Reiki In Chakras From Surrounding By Sixteen Colour Master Power",
    },
    {
      srNo: 45,
      subject:
        "Method Of Taking Reiki In Chakras From Front By Sixteen Colour Master Power",
    },
    {
      srNo: 46,
      subject:
        "Method Of Taking Reiki In Chakras From Front And Back By Sixteen Colour Master Power",
    },
    {
      srNo: 47,
      subject: "Method Of Giving Reiki In Spinal Cord Of An Absent Person -01",
    },
    {
      srNo: 48,
      subject: "Method Of Giving Reiki In Spinal Cord Of An Absent Person -02",
    },
    {
      srNo: 49,
      subject:
        "Method Of Giving Reiki To The Chakras From Surrounding To An Absent Person By Sixteen Colour",
    },
    {
      srNo: 50,
      subject:
        "Method Of Giving Reiki To The Chakras From Front To An Absent Person By Sixteen Colour",
    },
    {
      srNo: 51,
      subject:
        "Method Of Giving Reiki To The Chakras From Front And Back To An Absent Person By Sixteen Colour",
    },
    { srNo: 52, subject: "Purification Of Ajna Chakra" },
    { srNo: 53, subject: "21 Practicals (21 Sub Practicals)" },
    {
      srNo: 54,
      subject:
        "Practical For Implementing The Five Fundamental Principles Of Reiki In Life",
    },
    { srNo: 55, subject: "Self-Purification" },
    { srNo: 56, subject: "Swasthit - Practical To Become Stable In Self" },
    {
      srNo: 57,
      subject: "Practical To Make Every Pursuit Positive In All Chakras -01",
    },
    {
      srNo: 58,
      subject: "Practical To Make Every Pursuit Positive In All Chakras -02",
    },
    {
      srNo: 59,
      subject: "Practical To Make Every Pursuit Positive In All Chakras -03",
    },
    {
      srNo: 60,
      subject: "Practical To Make Every Pursuit Positive In All Chakras -04",
    },
    {
      srNo: 61,
      subject: "Process To Activate Inner Sadhguru (Highest Master)",
    },
    { srNo: 62, subject: "Opening Of Sahastrar Chakra" },
    { srNo: 63, subject: "Purification Of Foot Sole" },
    { srNo: 64, subject: "Method Of Accomplishing Symbols By Third Degree" },
    { srNo: 65, subject: "Growth Of Self Power" },
    {
      srNo: 66,
      subject: "Development Of Self-Power Along With Self-Purification",
    },
    { srNo: 67, subject: "Aura Body Cleansing (Old)" },
    { srNo: 68, subject: "Shield From Vertebrate Column" },
    { srNo: 69, subject: "Emotional Balance" },
    {
      srNo: 70,
      subject:
        "Practical For Avoiding Tantra And Killing Practices Or For Its Complete Destruction",
    },
  ],

  specialPraticals: [
    {
      srNo: 1,
      subject: "Practical For Protection From Novel Coronavirus (Covid-19)",
    },
    {
      srNo: 2,
      subject: "Practical For Destroying Each Type Of Negative Energy: Pending",
    },
    {
      srNo: 3,
      subject: "Other Uses Of Reiki: Corrections In Old File Pending",
    },
    { srNo: 4, subject: "Special Uses Of Reiki" },
    { srNo: 5, subject: "Detailed Gratitude Process" },
    {
      srNo: 6,
      subject:
        "Protection From Maran-Muth-Chot (Spiritual Malpractices): Only Names Are Mentioned In The Old Book Writing Practicals Is Pending",
    },
    { srNo: 7, subject: "Protection From Prets" },
  ],
};
