import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import consultationImg from "../../assets/img/services/consultation.svg";
import reikiImg from "../../assets/img/services/reiki.svg";
import medicineFreeImg from "../../assets/img/services/no-medicine.svg";
import energioImg from "../../assets/img/services/land.svg";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Services() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      offset: 80,
    });
  }, []);

  return (
    <Wrapper id="services" data-aos="zoom-in">
      <AnchorOffset />
      <div className="container">
        <HeaderInfo>
          <Title>Our Popular Services</Title>
          <Description>
            We provide personalized Reiki Courses, spiritual guidance, and
            wellness programs designed to promote inner peace, balance, and
            overall well-being.
          </Description>
        </HeaderInfo>
        <CardsContainer>
          {serviceData.map((service, index) => (
            <Card key={index}>
              <CardImage src={service.imageUrl} alt={service.title} />
              <CardContent>
                <h2>{service.title}</h2>
                <p>{service.subtitle}</p>
              </CardContent>
            </Card>
          ))}
        </CardsContainer>

        <LearnMoreButtonContainer>
          <Link to="/services/all-services">
            <StyledWrapper>
              <button className="learn-more">
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow" />
                </span>
                <span className="button-text">Learn More</span>
              </button>
            </StyledWrapper>
          </Link>
        </LearnMoreButtonContainer>
      </div>
    </Wrapper>
  );
}

const serviceData = [
  {
    title: "Reiki Training & Researches",
    subtitle: "Explore the transformative power of Reiki.",
    imageUrl: reikiImg,
  },
  {
    title: "Research on Living a Medicine-Free Life",
    subtitle: "Discover natural methods to enhance your health.",
    imageUrl: medicineFreeImg,
  },
  {
    title: "Expert Consultation and Research",
    subtitle: "Receive guidance from experienced professionals.",
    imageUrl: consultationImg,
  },
  {
    title: "Energio Land Treatment",
    subtitle: "Experience our holistic approach to healing.",
    imageUrl: energioImg,
  },
];

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding-top: 80px; /* Ensure spacing to avoid overlap */
  scroll-margin-top: 80px; /* Offset for fixed navbar */
  margin-bottom: 60px; /* Add margin below the header */

  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 80px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin-top: 5px;
    padding-top: 80px;
    margin-bottom: 20px;
  }
`;

const AnchorOffset = styled.div`
  height: 80px;
  margin-top: -80px;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: #04016a;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  padding: 10px 0;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.5rem;
  text-align: left;
  color: #34495e;
  background: rgba(255, 255, 0, 0.2);
  border-left: 5px solid #04016a;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  line-height: 1.6;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 0, 0.4);
  }

  /* Responsive description text */
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center cards on all screens */
`;

// Adjusted the card size for responsiveness
const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: calc(25% - 20px); /* Four cards per row by default with spacing */
  max-width: 300px; /* Prevent the cards from getting too large */
  min-width: 250px; /* Prevent the cards from shrinking too much */
  height: 350px; /* Fixed height for consistency */
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  /* For tablets, show 2 cards per row */
  @media (max-width: 1024px) {
    width: calc(50% - 20px);
  }

  /* For smaller screens, show 1 card per row */
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: auto;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
`;

const CardContent = styled.div`
  padding: 15px;

  h2 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2rem;
    color: #34495e;
  }
`;

const LearnMoreButtonContainer = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const StyledWrapper = styled.div`
  button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
  }

  button.learn-more {
    width: 12rem;
    height: auto;
  }

  button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #04016a;
    border-radius: 1.625rem;
  }

  button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
  }

  button.learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }

  button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
  }

  button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }

  button:hover .circle {
    width: 100%;
  }

  button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
  }

  button:hover .button-text {
    color: #fff;
  }

  /* Add mobile styles for no hover state, triggering the hover effect automatically */
  @media (max-width: 768px) {
    button.learn-more .circle {
      width: 100%; /* Make the circle span the full width */
    }

    button.learn-more .circle .icon.arrow {
      background: #fff;
      transform: translate(1rem, 0); /* Move the arrow like in hover */
    }

    button.learn-more .button-text {
      color: #fff; /* Change text color like on hover */
    }
  }
`;
