import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import CourseImg5 from "../../assets/img/courses/Course5.png";

export default function ReikiCrystalBasic() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper data-aos="zoom-in-right">
      <ContentWrapper>
        <MainHeading>
          <Title>Reiki Crystal Basic</Title>
          <CourseImage5Styled src={CourseImg5} alt="Reiki Crystal Basic" />{" "}
        </MainHeading>
        <SecondaryHeading>
          <Message>
            🧐 Getting ready for you! We are planning to give you more. Stay
            tuned!
          </Message>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 100px 0 0 0; // Ensure no padding is applied here
  margin: 0; // Remove margin around the wrapper
  width: 100%; // Ensure the wrapper takes full width

  @media (max-width: 768px) {
    padding-top: 60px; /* Reduce padding-top for tablet screens */
  }

  @media (max-width: 480px) {
    padding-top: 60px; /* Further reduce padding-top for small mobile screens */
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px; // You may keep this if you want to constrain the content width
  margin: 0 auto;
  padding: 0 40px; // Remove any extra padding here if present
  text-align: center;
  width: 100%; // Ensure it uses the full available width
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const CourseImage5Styled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: #333;
  background-color: #e8e0f8; /* Light purple background */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  margin: 20px auto; /* Center it */
  max-width: 90%; /* Increase width */
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-3px); /* 3D effect */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-image: linear-gradient(45deg, #a67c00 70%, #04016a 30%);
  color: white;
  padding: 10px 20px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
  transition: transform 0.3s, background-position 1s;

  /* Continuous flowing gradient effect */
  background-size: 400% 400%; /* Large background for animation */
  animation: gradientFlow 2s ease infinite;

  &:hover {
    transform: scale(1.1);
  }

  /* Keyframes to animate the gradient */
  @keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
