import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import experience from "../../assets/img/WhyChooseUs/experience.png";
import successful from "../../assets/img/WhyChooseUs/successful.png";
import rating from "../../assets/img/WhyChooseUs/rating.png";
import healing from "../../assets/img/WhyChooseUs/healing.png";

const SectionWrapper = styled.section`
  padding: 100px 20px;
  background: linear-gradient(to bottom, transparent, silver 100%, black);
  text-align: center;

  @media (max-width: 768px) {
    padding: 50px 10px;
  }

  @media (max-width: 480px) {
    padding: 30px 5px;
  }
`;

const SectionHeading = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #04016a;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const SectionDescription = styled.p`
  font-size: 1.2rem;
  color: #555;
  margin: 0 auto 40px;
  max-width: 900px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 200px;

  @media (max-width: 768px) {
    width: 150px;
  }

  @media (max-width: 480px) {
    width: 120px;
    padding: 15px;
  }
`;

const StatIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 15px;

  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
  }
`;

const StatNumber = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: #04016a;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const StatText = styled.p`
  font-size: 1rem;
  color: #555;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const BookButton = styled.button`
  margin-top: 40px;
  padding: 15px 15px;
  background: #04016a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease;
  position: relative;

  a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    background: #020146;
  }

  @media (max-width: 480px) {
    padding: 10px 15px;
    font-size: 1.2rem;
  }
`;

export default function WhyChooseUsSection() {
  const stats = useMemo(
    () => [
      {
        id: 1,
        icon: experience,
        target: 25,
        text: "Years of Experience",
        addPlus: true,
      },
      {
        id: 2,
        icon: successful,
        target: 5600,
        text: "Successful Students",
        addPlus: true,
      },
      {
        id: 3,
        icon: rating,
        target: 1000,
        text: "Positive Reviews",
        addPlus: true,
      },
      {
        id: 4,
        icon: healing,
        target: 100,
        text: "Reiki Charged Products",
        isPercentage: true,
      },
    ],
    []
  );

  const [counts, setCounts] = useState(stats.map(() => 0));

  useEffect(() => {
    const intervals = stats.map((stat, index) => {
      const increment = Math.max(1, Math.ceil(stat.target / 50));
      return setInterval(() => {
        setCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          newCounts[index] = Math.min(
            newCounts[index] + increment,
            stat.target
          );
          return newCounts;
        });
      }, 50);
    });

    return () => intervals.forEach((interval) => clearInterval(interval));
  }, [stats]);

  return (
    <SectionWrapper data-aos="zoom-out-up">
      <SectionHeading>
        Why Choose Us as the Solution to All Your Life Problems?
      </SectionHeading>
      <SectionDescription>
        With years of experience in Reiki, meditation, and various spiritual
        practices, we have guided thousands of individuals toward achieving a
        balanced, harmonious life, free from the need for medication.
      </SectionDescription>
      <StatsWrapper>
        {stats.map((stat, index) => (
          <StatItem key={stat.id}>
            <StatIcon src={stat.icon} alt={stat.text} />
            <StatNumber>
              {stat.isPercentage
                ? `${counts[index]}%`
                : `${counts[index]}${stat.addPlus ? "+" : ""}`}
            </StatNumber>
            <StatText>{stat.text}</StatText>
          </StatItem>
        ))}
      </StatsWrapper>
      <BookButton>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf-PrCbHJxNymRlNJSgsiUDYDSDE1OJvumAP-YPFIojLptiZA/viewform?"
          target="_blank"
          rel="noopener noreferrer"
        >
          🎗️🎁 Book an Appointment 🎯📞
        </a>
      </BookButton>
    </SectionWrapper>
  );
}
