// Reiki3rdDegree.jsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import CourseImg3 from "../../assets/img/courses/Course-3.png";
import { indexDataFor3rdDegree } from "../data/indexDataFor3rdDegree";

export default function Reiki3rdDegree() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [showButton, setShowButton] = useState(false);
  const [showIndex, setShowIndex] = useState(false);
  const [activeTable, setActiveTable] = useState(null);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const toggleIndex = () => setShowIndex((prev) => !prev);

  const toggleTable = (tableName) => {
    setActiveTable((prev) => (prev === tableName ? null : tableName));
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <MainHeading>
          <Title>Reiki 3rd Degree</Title>
          <CourseImage3Styled src={CourseImg3} alt="Reiki 3rd degree" />{" "}
        </MainHeading>

        {/* Add the Show Index Button and Modal */}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <ShowIndexButton
              onClick={toggleIndex}
              style={{ position: "relative" }}
            >
              Show Index
              <Badge>See Overview!</Badge>
            </ShowIndexButton>
          </div>
        </div>

        {showIndex && (
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <ModalTitle>Index</ModalTitle>
                <CloseButton onClick={toggleIndex}>✖</CloseButton>
              </ModalHeader>

              {/* Third Degree Theory */}
              <SectionTitle onClick={() => toggleTable("thirdDegreeTheory")}>
                ▶️ Third Degree Theory
              </SectionTitle>
              {activeTable === "thirdDegreeTheory" && (
                <TableContainer>
                  <IndexTable>
                    <thead>
                      <tr>
                        <TableHeader1>Sr. No.</TableHeader1>
                        <TableHeader2>Subject</TableHeader2>
                      </tr>
                    </thead>
                    <tbody>
                      {indexDataFor3rdDegree.thirdDegreeTheory.map(
                        (item, index) => (
                          <TableRow key={index}>
                            <TableData>{item.srNo}</TableData>
                            <TableData>{item.subject}</TableData>
                          </TableRow>
                        )
                      )}
                    </tbody>
                  </IndexTable>
                </TableContainer>
              )}

              {/* Third Degree Practicals */}
              <SectionTitle
                onClick={() => toggleTable("thirdDegreePracticals")}
              >
                ▶️ Third Degree Practicals
              </SectionTitle>
              {activeTable === "thirdDegreePracticals" && (
                <TableContainer>
                  <IndexTable>
                    <thead>
                      <tr>
                        <TableHeader1>Sr. No.</TableHeader1>
                        <TableHeader2>Subject</TableHeader2>
                      </tr>
                    </thead>
                    <tbody>
                      {indexDataFor3rdDegree.thirdDegreePracticals.map(
                        (item, index) => (
                          <TableRow key={index}>
                            <TableData>{item.srNo}</TableData>
                            <TableData>{item.subject}</TableData>
                          </TableRow>
                        )
                      )}
                    </tbody>
                  </IndexTable>
                </TableContainer>
              )}

              {/* Special Practicals */}
              <SectionTitle onClick={() => toggleTable("specialPracticals")}>
                ▶️ Special Practicals
              </SectionTitle>
              {activeTable === "specialPracticals" && (
                <TableContainer>
                  <IndexTable>
                    <thead>
                      <tr>
                        <TableHeader1>Sr. No.</TableHeader1>
                        <TableHeader2>Subject</TableHeader2>
                      </tr>
                    </thead>
                    <tbody>
                      {indexDataFor3rdDegree.specialPraticals.map(
                        (item, index) => (
                          <TableRow key={index}>
                            <TableData>{item.srNo}</TableData>
                            <TableData>{item.subject}</TableData>
                          </TableRow>
                        )
                      )}
                    </tbody>
                  </IndexTable>
                </TableContainer>
              )}

              {/* Copyright Notice */}
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <p style={{ fontWeight: "bold", marginBottom: "15px" }}>
                  Copyright © 2021 by Bharattkumar C. Mistry.
                </p>
              </div>
            </ModalContent>
          </ModalOverlay>
        )}

        <SecondaryHeading>
          <Paragraph>
            Our 3rd degree Reiki power heals the Kundalini shakti (Serpentine
            Power) of the Reiki channel. The power of the 3rd Degree awakens and
            uplifts the Kundalini energy within us, guiding us to connect with
            our true inner self, the Sadguru. It awakens us to our spiritual
            essence, fulfills us with complete abundance, and helps set the
            energy to connect with the Divine. At Amruttam, we take pride in
            blending advanced energy work with authentic guidance, ensuring each
            student gains a meaningful, supportive experience. This approach
            sets us apart as a leading institute in Reiki courses. Designed for
            those ready to reach their fullest potential, our 3rd Degree Course
            offers a life-changing path for serious Reiki practitioners.
          </Paragraph>

          <SubHeading>Course Duration & Structure</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Length</strong>: 18 months
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Classes</strong>: Twice per month (every 15 days)
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Duration</strong>: 1.5 hours per session
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Format</strong>: Available in both online and offline
                formats
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Content</strong>: Along with old theory & practicals,
                emphasis on newly added, results-based practicals and theory
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Course Features</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Audio recordings of all practical sessions
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Personalized consultations</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                One-on-one in-class sessions (available as needed)
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Reiki Kit Includes</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Comprehensive course book (298 pages)</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText> 6 foundational 3rd Degree symbols</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                4 types of specialized Reiki symbols developed through 20 years
                of research by Reiki Master Bharatt Mistry
              </BulletText>
            </BulletItem>

            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Kit bag</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Counter, pen, and additional stationery items
              </BulletText>
            </BulletItem>
          </BulletList>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 100px 0 0 0; // Ensure no padding is applied here
  margin: 0; // Remove margin around the wrapper
  width: 100%; // Ensure the wrapper takes full width

  @media (max-width: 768px) {
    padding-top: 60px; /* Reduce padding-top for tablet screens */
  }

  @media (max-width: 480px) {
    padding-top: 60px; /* Further reduce padding-top for small mobile screens */
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px; // You may keep this if you want to constrain the content width
  margin: 0 auto;
  padding: 0 40px; // Remove any extra padding here if present
  text-align: center;
  width: 100%; // Ensure it uses the full available width
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a; /* Background color */
  padding: 15px 20px; /* Padding */
  border-radius: 8px; /* Rounded corners */
  margin: 30px 0 15px; /* Margin */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  /* Responsive styles */
  @media (max-width: 1200px) {
    font-size: 1.8rem; /* Slightly smaller for medium screens */
  }

  @media (max-width: 900px) {
    font-size: 1.6rem; /* Smaller for smaller tablets */
  }

  @media (max-width: 600px) {
    font-size: 1.4rem; /* Smaller for mobile devices */
    padding: 10px 15px; /* Adjust padding */
  }

  @media (max-width: 400px) {
    font-size: 1.2rem; /* Even smaller for very small screens */
    padding: 8px 12px; /* Further adjust padding */
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%; /* Increase width */
  margin: 20px auto; /* Center it */
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start; /* Align bullet and text */
  margin-bottom: 15px; /* Increased spacing */
  line-height: 1.5;
  padding-left: 10px; /* Additional padding for spacing */
  text-align: left;
  font-size: 1.1rem;
  color: #333; /* Darker text color */
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px; /* Space between bullet and text */
`;

const BulletText = styled.span`
  flex: 1; /* Allow the text to take up remaining space */
  background-color: #f9f9f9; /* Light background for list items */
  border-radius: 5px; /* Rounded corners for list items */
  padding: 10px; /* Padding inside list items */
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff; /* Light blue background on hover */
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const ShowIndexButton = styled.button`
  position: relative;
  margin: 20px auto;
  padding: 15px 25px;
  background: linear-gradient(45deg, #ff6a00, #ee0979);
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, background-color 0.3s;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: rgba(255, 106, 0, 0.4);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.3);
      opacity: 0.7;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
`;

const Badge = styled.span`
  position: absolute;
  top: 0; /* Align at the top */
  right: 0; /* Align at the right corner */
  transform: translate(90%, -50%); /* Offset the badge */
  background-color: #ff6a00;
  color: white;
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  /* Responsive adjustments */
  @media (max-width: 768px) {
    font-size: 0.7rem; /* Slightly smaller font size for tablets */
    padding: 4px 8px; /* Reduce padding */
    transform: translate(80%, -40%); /* Adjust position on smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.6rem; /* Smaller font size for mobile */
    padding: 3px 6px; /* Reduce padding further */
    transform: translate(70%, -30%); /* Adjust position for tighter layout */
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  overflow-y: auto;
  max-height: 80vh;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  color: #04016a;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ff6a00;
  cursor: pointer;
`;

const SectionTitle = styled.h3`
  cursor: pointer;
  font-size: 1.2rem;
  color: #04016a;
  margin-top: 10px;
  font-weight: bold;
  text-align: left;
`;

const TableContainer = styled.div`
  margin-top: 10px;
  max-height: 400px;
  overflow-y: auto;
`;

const IndexTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensure the table respects the fixed layout */
`;

const TableHeader1 = styled.th`
  background: #f5f5f5;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  width: 20%; /* Explicitly set width for Sr No */
`;

const TableHeader2 = styled.th`
  background: #f5f5f5;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  width: 80%; /* Explicitly set width for Sr No */
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableData = styled.td`
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;

  /* Apply width distribution */
  &:first-child {
    width: 20%; /* Set Sr No column width to 20% */
    text-align: center;
  }
  &:nth-child(2) {
    width: 80%; /* Set Subject column width to 80% */
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const CourseImage3Styled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-image: linear-gradient(45deg, #a67c00 70%, #04016a 30%);
  color: white;
  padding: 10px 20px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
  transition: transform 0.3s, background-position 1s;

  /* Continuous flowing gradient effect */
  background-size: 400% 400%; /* Large background for animation */
  animation: gradientFlow 2s ease infinite;

  &:hover {
    transform: scale(1.1);
  }

  /* Keyframes to animate the gradient */
  @keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
