import React, { useState } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

export default function Sidebar({ sidebarOpen, closeSidebar }) {
  const [activeDropdown, setActiveDropdown] = useState(null);

  // Handle back button
  const handleBack = () => setActiveDropdown(null);

  // Handle close sidebar and reset to main menu
  const handleCloseSidebar = () => {
    setActiveDropdown(null);
    closeSidebar();
  };

  const dropdownContent = {
    about: [
      { to: "/about", label: "About Us" },
      { to: "/about/gallery", label: "Gallery" },
    ],
    courses: [
      { to: "/courses/reiki-course", label: "Reiki Course" },
      {
        to: "/courses/reiki-1st-and-2nd-degree",
        label: "Reiki 1st & 2nd Degree",
      },
      { to: "/courses/reiki-3rd-degree", label: "Reiki 3rd Degree" },
      { to: "/courses/pendulum", label: "Pendulum (Dowsing)" },
      { to: "/courses/reiki-crystal-basic", label: "Reiki Crystal Basic" },
      {
        to: "/courses/reiki-personalized",
        label: "Reiki Crystal Personalized",
      },
    ],
    services: [{ to: "/services/all-services", label: "All Services" }],
    products: [
      { to: "/products/arpg", label: "ARPG" },
      { to: "/products/amruttam-batti", label: "Amruttam Batti" },
      { to: "/products/life-protector", label: "Life Protector" },
      { to: "/products/store", label: "Store" },
    ],
  };

  return (
    <>
      <Overlay sidebarOpen={sidebarOpen} onClick={handleCloseSidebar} />
      <Wrapper sidebarOpen={sidebarOpen}>
        <SidebarHeader>
          {activeDropdown && <BackButton onClick={handleBack}>&lt;</BackButton>}
          <CloseButton onClick={handleCloseSidebar}>&times;</CloseButton>
        </SidebarHeader>

        {activeDropdown ? (
          <DropdownSection>
            <DropdownTitle>{activeDropdown.toUpperCase()}</DropdownTitle>
            {dropdownContent[activeDropdown].map((item) => (
              <RouterLink
                to={item.to}
                onClick={handleCloseSidebar}
                key={item.to}
              >
                {item.label}
              </RouterLink>
            ))}
          </DropdownSection>
        ) : (
          <UlStyle>
            <DropdownItem>
              <RouterLink to="/" onClick={handleCloseSidebar}>
                Home
              </RouterLink>
            </DropdownItem>
            <DropdownItem onClick={() => setActiveDropdown("about")}>
              <span>About</span>
              <RightArrow>&gt;</RightArrow>
            </DropdownItem>
            <DropdownItem onClick={() => setActiveDropdown("courses")}>
              <span>Courses</span>
              <RightArrow>&gt;</RightArrow>
            </DropdownItem>
            <DropdownItem onClick={() => setActiveDropdown("services")}>
              <span>Services</span>
              <RightArrow>&gt;</RightArrow>
            </DropdownItem>
            <DropdownItem onClick={() => setActiveDropdown("products")}>
              <span>Products</span>
              <RightArrow>&gt;</RightArrow>
            </DropdownItem>
            <DropdownItem>
              <RouterLink to="/contact" onClick={handleCloseSidebar}>
                Contact
              </RouterLink>
            </DropdownItem>
          </UlStyle>
        )}
      </Wrapper>
    </>
  );
}

// Styled components

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: ${(props) => (props.sidebarOpen ? "block" : "none")};
`;

const Wrapper = styled.nav`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebarOpen ? "0" : "-100%")};
  z-index: 9999;
  transition: right 0.3s ease;
  background: linear-gradient(135deg, #04016a, #4b0082);
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  overflow-y: auto;
`;

const SidebarHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  font-size: 2.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #ffeb80;
  }

  &:focus {
    outline: none;
  }
`;

const BackButton = styled.button`
  font-size: 1.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #ffeb80;
  }

  &:focus {
    outline: none;
  }
`;

const UlStyle = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const DropdownItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  margin: 0 20px;

  a {
    color: inherit;
    text-decoration: none;
    flex-grow: 1;
  }

  &:hover {
    color: #ffeb80;
  }
`;

const RightArrow = styled.span`
  font-size: 1.5rem;
  color: #fff;
`;

const DropdownSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 20px;

  a {
    font-size: 1.5rem;
    color: #fff;
    text-decoration: none;
    padding: 5px;
    margin: 5px 0;
    display: block;

    &:hover {
      color: #ffeb80;
    }
  }
`;

const DropdownTitle = styled.h2`
  font-size: 1.5rem;
  padding-left: 10px;
  color: #c0c0c0;
  margin-bottom: 20px;
  text-transform: uppercase;
`;
