import React, { useRef } from "react";
import styled from "styled-components";
import mikaoImage from "../../assets/img/Dr. Usui.png";
import { useNavigate } from "react-router-dom";
import downArrow from "../../assets/img/down-arrow.png"; // Importing down arrow image

// Main Heading Section (unchanged)
const MainHeadingWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(135deg, #04016a, #ffeb3b);
  padding: 100px 20px;
  color: #fff;
  position: relative;
  overflow: hidden;
`;

const MainHeadingText = styled.h1`
  font-size: 4rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  letter-spacing: 2px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

// Scroll Button Section (down arrow) directly inside MainHeadingWrapper
const DownArrowButtonWrapper = styled.div`
  position: absolute;
  bottom: 35px; /* Adjust to position the arrow just below the heading */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; /* Make it clickable */
`;

const DownArrow = styled.img`
  width: 50px; /* Adjust the size of the arrow */
  height: 50px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(5px); /* Slight hover effect */
  }
`;

// Main content section
const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  background: #f9f9f9;
  max-width: 1200px;
  max-height: 1000px;
  margin: 0 auto;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 50px 20px;
    gap: 60px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 600px;

  @media (max-width: 768px) {
    order: 2;
  }
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #04016a;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
`;

const ReadMoreButton = styled.button`
  --color: #04016a;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s, background 0.5s, border 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background: var(--color);

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }

  &:hover {
    color: var(--color);
    background: #fff;
    border-color: var(--color);

    &::before {
      top: -30px;
      left: -30px;
      background: #fff;
    }
  }

  &:active::before {
    background: #3a0ca3;
    transition: background 0s;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 426px;
  margin-top: 40px;

  @media (max-width: 768px) {
    order: 1;
  }
`;

const Img = styled.img`
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  width: 426px;
  height: auto;

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 560px) {
    width: 80%;
  }
`;

const AuthorTextWrapper = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, #ffeb3b, #ffffff);
  text-align: center;
  padding: 6px 0;
  z-index: 9;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 560px) {
    width: 80%;
  }
`;

const AuthorText = styled.p`
  font-size: 25px;
  color: #04016a;
  margin: 0;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
`;

const AboutMikaoUsui = () => {
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const sectionRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <MainHeadingWrapper>
        <MainHeadingText>Discover the Legacy of Dr. Mikao Usui</MainHeadingText>

        {/* Down Arrow Button directly inside MainHeadingWrapper */}
        <DownArrowButtonWrapper onClick={scrollToSection}>
          <DownArrow src={downArrow} alt="Down Arrow" />
        </DownArrowButtonWrapper>
      </MainHeadingWrapper>

      <SectionWrapper ref={sectionRef}>
        <ImageWrapper>
          <Img
            ref={imageRef}
            className="radius8"
            src={mikaoImage}
            alt="Dr. Mikao Usui"
          />
          <AuthorTextWrapper>
            <AuthorText>Dr. Mikao Usui</AuthorText>
          </AuthorTextWrapper>
        </ImageWrapper>
        <ContentWrapper>
          <Heading>About Dr. Mikao Usui</Heading>
          <Paragraph>
            Dr. Mikao Usui was a Japanese Buddhist monk who is credited with
            developing the spiritual practice of Reiki. His teachings have
            inspired millions worldwide.
          </Paragraph>
          <ReadMoreButton onClick={() => navigate("/mikao-usui")}>
            Read More
          </ReadMoreButton>
        </ContentWrapper>
      </SectionWrapper>
    </>
  );
};

export default AboutMikaoUsui;
