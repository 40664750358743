import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import ARPGImage from "../../assets/img/products/arpg.png";
import ClientImage6 from "../../assets/img/client/Client.png";
import PrajaktaShirke from "../../assets/img/client/PrajaktaShirke1.jpeg";
import BlankProfile from "../../assets/img/client/blank-profile-picture.png";
import BackgroundImage from "../../assets/img/bg5.jpeg";

export default function ARPG() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Wrapper data-aos="zoom-out-down">
      <ContentWrapper>
        <MainHeading>
          <Title>Amruttam Reiki Powered Globules(ARPG)</Title>
          <ARPGImageStyled src={ARPGImage} alt="ARPG" />{" "}
          {/* ARPG Image Below Title */}
        </MainHeading>
        <SecondaryHeading>
          <Paragraph>
            Amruttam Reiki Powered Globules (ARPG) is a natural health product
            that helps improve energy and overall wellness. Each dose contains
            special globules that offer the benefits of Reiki healing, helping
            with various health issues, from digestion to emotional support.
            It’s easy to order, and you’ll get free delivery right to your door.
          </Paragraph>

          <SubHeading>Key Benefits of ARPG</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Body and Mind Healing</strong>: ARPG provides powerful
                healing benefits, addressing over 200+ physical conditions and
                supporting recovery from 90% of mental health issues for
                complete, balanced health.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Mental Well-being</strong>: Helps eliminate mental
                disorders like depression, anxiety etc. and provides clarity,
                promoting a state of calm and improved mental health.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Medicine-Free</strong>: ARPG is a holistic product that
                provides wellness benefits with no medicine involved.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Heart Health Support</strong>: Effective for
                heart-related issues such as heart block, bypass, and
                post-cardiac surgery recovery, providing a gentle energy boost
                and aiding in faster recovery.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Liver and Pancreas Care</strong>: Useful for conditions
                involving the liver and pancreas, aiding in digestive health and
                managing associated diseases.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Comprehensive Health Benefits</strong>: ARPG aids in
                managing symptoms of common ailments and more specific health
                issues, such as: Cough and fever relief, Heel pain (plantar
                fasciitis) support, Lower back pain and shoulder injury relief.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Maternal and Child Health</strong>: Ensures up to 98%
                normal childbirth, supports pregnant women’s health, and
                promotes overall wellness for infants and children. ARPG also
                assists in behavioral improvements for children.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Kidney Health</strong>: Addresses kidney-related
                conditions, including those requiring dialysis, and supports the
                management of elevated creatinine levels.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Financial and Life Development</strong>: Facilitates an
                energetic shift to support financial growth and development in
                life pursuits.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Energy Revitalization</strong>: Supports revitalizing
                the body's energy, enhancing focus, and improving memory and
                recollection capabilities.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Business Growth</strong>: ARPG helps your business
                expand and encourages new ideas.
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Dosage & Usage</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>1 Dose</strong>: Take a minimum 3 globules at a time
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>1 Bottle</strong>: Each bottle has about 60-63 doses
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>1 Box</strong>: Each box contains around 190 doses
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Healing Power</strong>: Each globule gives you the
                benefits of a 40-45 minute Reiki healing session
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Usage Instructions</strong>: You will receive simple
                instructions on how to use ARPG upon your purchase
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Ordering Details</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Easy Ordering</strong>: Simply call us to order your
                Amruttam Reiki Powered Globules for a smooth and convenient
                experience
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Free Home Delivery</strong>: Get free delivery straight
                to your door with every order
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Box Contents</strong>: Each box includes three bottles,
                providing you with a long-lasting supply
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Price</strong>: One box is priced at{" "}
                <PriceHighlight>₹1350/- INR</PriceHighlight>
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                <strong>Note</strong>: For free home delivery, you need to order
                a minimum of one box, which includes three bottles
              </BulletText>
            </BulletItem>
          </BulletList>

          <SubHeading>Contact Details</SubHeading>
          <ContactBox>
            <ContactItem>
              <a href="tel:+919909000539">📞 Contact: +91 9909000539</a>
            </ContactItem>
            <ContactItem>
              <a
                href="https://maps.app.goo.gl/cxtGGoyumwyHRQkr9"
                target="_blank"
                rel="noopener noreferrer"
              >
                🏢 Address: HG-2, International Business Centre, Piplod, Surat
                395007
              </a>
            </ContactItem>
            <ContactItem>
              <a href="mailto:amruttamreikiyoga@gmail.com">
                📧 Email: amruttamreikiyoga@gmail.com
              </a>
            </ContactItem>
          </ContactBox>
        </SecondaryHeading>
        {/* Insert Testimonials Component */}
        <Testimonials />
        <section
          className="testimonial-section"
          aria-labelledby="testimonials-heading"
        >
          <h2 id="testimonial-heading">
            Explore the journey through the videos below
          </h2>
          <div className="testimonial-content">
            <div className="testimonial-video">
              <iframe
                src="https://www.youtube.com/embed/q8M2g4HsudI?si=CXYUK03zXhBTCaoy"
                title="Testimonial Video 1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                loading="lazy"
                allowFullScreen
                aria-label="Testimonial video 1"
              ></iframe>
            </div>
            <div className="testimonial-video">
              <iframe
                src="https://www.youtube.com/embed/WOKQhoHH_d8?si=oMncsuFbt_5kyWc1"
                title="Testimonial Video 1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                loading="lazy"
                allowFullScreen
                aria-label="Testimonial video 1"
              ></iframe>
            </div>
            <div className="testimonial-video">
              <iframe
                src="https://www.youtube.com/embed/-c7Z5-Xc5DI?si=WF9Y7c6XOUzYxgH9"
                title="Testimonial Video 2"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                loading="lazy"
                allowFullScreen
                aria-label="Testimonial video 2"
              ></iframe>
            </div>
            <div className="testimonial-video">
              <iframe
                src="https://www.youtube.com/embed/a_oMCHD6jTg?si=DaPI1AQW0VqNcuuP"
                title="Testimonial Video 3"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                loading="lazy"
                allowFullScreen
                aria-label="Testimonial video 3"
              ></iframe>
            </div>
          </div>
        </section>
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
}

const Testimonials = () => (
  <TestimonialsSection>
    <SectionTitle>What Our Clients Say About ARPG</SectionTitle>
    <TestimonialCard>
      <TestimonialImage src={PrajaktaShirke} alt="Client 1" />
      <TestimonialContent>
        <TestimonialText>
          “Hello, Namaste. My name is Prajakta Shirke..I live in CT, USA. I
          would like to share my experience with Amruttam Reiki Power Globules
          which is also called as Amruttam RPG. RPG helped me to regain my
          energy during pregnancy. I had a healthy pregnancy and delivery after
          taking Amruttam powered globules. When I went into labor, I literally
          didn't feel any pain. I delivered my baby within 10 to 15 mins. I was
          not exhausted. My baby was born healthy and happy. Overall it was an
          amazing experience. RPG has Nirakar Paramtatva's energy. This energy
          sets and opens the best pathways in any situation. The energy works in
          such a way that in any good or bad situations, you get the best
          guidances. You get all the strength to deal with the situation. We
          human beings have negative thoughts. We still don't know how to train
          our minds. That is the reason our good or bad thoughts get registered
          in our subconscious mind. RPG help you to think positively when you
          get such unwanted thoughts. It helps you to become aware of what you
          think, the way you think. I would definitely recommend RPG. Sometimes
          when we don't get a chance to do any spiritual practice, RPG is a
          great way to fuel your body and boost your energy.”
        </TestimonialText>
        <ClientName>Prajakta Shirke,</ClientName>
        <ClientPosition>MCA and Data Analyst</ClientPosition>
      </TestimonialContent>
    </TestimonialCard>

    <TestimonialCard>
      <TestimonialImage src={ClientImage6} alt="Client 2" />
      <TestimonialContent>
        <TestimonialText>
          “Amruttam Reiki Yoga is giving astonishing results to the patients
          with chronic conditions. I Narendra Hiralal Joshi 74, a retired
          journalist,am suffering from kidney problems and about 14 months ago.
          I was rushing towards dialysis because my serum creatinine was
          increasing rapidly. It was used to be within limits ranging between2
          to 3 but prior to March 2023 it begin to rise and touched the level of
          6.2 and due to my age my veins were narrow. I was advised fistula
          surgery. Almost all doctors including the nephrologist told me firmly
          that like age, serum creatinine level cannot go back. Of course the
          speed of its increase can be slowed down. On 8th March 2023, I had
          undergone the fistula surgery. I was discharged on the 9th. On the 11
          th, Mr Bharat Mistry an old friend of mine called me for some work but
          I narrated my position to him. He said,"My work can wait ,but I will
          treat you with my Amruttam Reiki Power therapy. "He and his wife Ms
          Anju Mistry , both started my treatment with Amruttam Reiki Powered
          globules- RPG and the cube. Within one and half month my serum
          creatinine was lowered to around 4.5 and within about six months or so
          it was 3.8. Other parameters too were almost normal. The results so
          far are astonishing to the doctors too..”
        </TestimonialText>
        <ClientName>Narendra Joshi,</ClientName>
        <ClientPosition>Ex-Chief Editor (Gujarat Mitra)</ClientPosition>
      </TestimonialContent>
    </TestimonialCard>

    <TestimonialCard>
      <TestimonialImage src={BlankProfile} alt="Client 3" />
      <TestimonialContent>
        <TestimonialText>
          “I want to share my personal experience with the Amruttam Reiki Power
          Globules [RPG]. My dad has heart muscle working only around 25% to 35%
          and was in critical condition in May 2022. We gave him continuous RPG
          for about 3-4 days and after his successful bypass surgery, his heart
          was about 45% which was not expected by the Doctors. The RPG worked on
          him wonderfully and also helped in avoiding dangerous post operation
          complications. He is diabetic and it did not affect his sugar levels.
          I highly recommend this. On a separate note,intuitively, I gave
          Amruttam RPG to my pet who was in pain and it helped relieve its
          suffering. Very little is available for pets, I recommend it for
          helping your pets as well.”
        </TestimonialText>
        <ClientName>Manpreet Rajpal,</ClientName>
        <ClientPosition></ClientPosition>
      </TestimonialContent>
    </TestimonialCard>
  </TestimonialsSection>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 100px 0 0 0; // Ensure no padding is applied here
  margin: 0; // Remove margin around the wrapper
  width: 100%; // Ensure the wrapper takes full width

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  .testimonial-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-items: center;
  }

  iframe {
    width: 100%;
    max-width: 560px;
    aspect-ratio: 16 / 9; /* Ensure responsive aspect ratio */
    border: none;
  }

  @media (max-width: 768px) {
    padding-top: 60px;

    h2 {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    padding-top: 60px;

    h2 {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 768px) {
    padding-top: 60px; /* Reduce padding-top for tablet screens */
  }

  @media (max-width: 480px) {
    padding-top: 60px; /* Further reduce padding-top for small mobile screens */
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px; // You may keep this if you want to constrain the content width
  margin: 0 auto;
  padding: 0 40px; // Remove any extra padding here if present
  text-align: center;
  width: 100%; // Ensure it uses the full available width
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a; /* Background color */
  padding: 15px 20px; /* Padding */
  border-radius: 8px; /* Rounded corners */
  margin: 30px 0 15px; /* Margin */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  /* Responsive styles */
  @media (max-width: 1200px) {
    font-size: 1.8rem; /* Slightly smaller for medium screens */
  }

  @media (max-width: 900px) {
    font-size: 1.6rem; /* Smaller for smaller tablets */
  }

  @media (max-width: 600px) {
    font-size: 1.4rem; /* Smaller for mobile devices */
    padding: 10px 15px; /* Adjust padding */
  }

  @media (max-width: 400px) {
    font-size: 1.2rem; /* Even smaller for very small screens */
    padding: 8px 12px; /* Further adjust padding */
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%; /* Increase width */
  margin: 20px auto; /* Center it */
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start; /* Align bullet and text */
  margin-bottom: 15px; /* Increased spacing */
  line-height: 1.5;
  padding-left: 10px; /* Additional padding for spacing */
  text-align: left;
  font-size: 1.1rem;
  color: #333; /* Darker text color */
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px; /* Space between bullet and text */
`;

const BulletText = styled.span`
  flex: 1; /* Allow the text to take up remaining space */
  background-color: #f9f9f9; /* Light background for list items */
  border-radius: 5px; /* Rounded corners for list items */
  padding: 10px; /* Padding inside list items */
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff; /* Light blue background on hover */
  }
`;

const ARPGImageStyled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const PriceHighlight = styled.span`
  font-weight: bold;
  color: #aa6c39; /* Highlight color */
  font-size: 1.2rem; /* Slightly larger font */
`;

const ContactBox = styled.div`
  background-color: #f0f8ff;
  border: 1px solid #b0c4de;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const ContactItem = styled.div`
  font-size: 1.1rem;
  margin: 10px 0;
  color: #333;
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const TestimonialsSection = styled.section`
  width: 100%;
  min-height: 100vh; /* Minimum full viewport height */
  padding: 40px 20px; /* Add padding to ensure proper spacing */
  background-image: url(${BackgroundImage}); /* Add your background image here */
  background-size: cover; /* Ensure the background image covers the entire section */
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* White text for contrast */
  box-sizing: border-box; /* Include padding in height calculation */
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const SectionTitle = styled.h2`
  color: #1e3c61;
  font-size: 2.5rem; /* Adjust font size */
  font-weight: bold;
  margin-bottom: 30px; /* Add spacing below the title */
  text-align: center;
  letter-spacing: 1.5px; /* Optional for styling */

  @media (max-width: 768px) {
    font-size: 2rem; /* Smaller font on mobile */
    margin-bottom: 20px;
  }
`;

const TestimonialCard = styled.div`
  max-width: 1200px; /* Restrict max width for content */
  width: 90%; /* Make it responsive for smaller screens */
  margin: 20px auto;
  background: #04016a; /* Background color for the card */
  border-radius: 15px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack the content vertically */
    padding: 20px 10px;
  }
`;

const TestimonialImage = styled.img`
  width: 100px; /* Adjust size of the testimonial image */
  height: 100px;
  border-radius: 50%; /* Circular image */
  object-fit: cover;
  border: 3px solid #fff; /* White border */
  margin-bottom: 20px; /* Add margin for spacing in mobile view */

  @media (max-width: 768px) {
    width: 80px; /* Smaller image size on mobile */
    height: 80px;
  }
`;

const TestimonialContent = styled.div`
  flex: 1;
  text-align: center; /* Center text on mobile */

  @media (max-width: 768px) {
    text-align: center; /* Left-align text in mobile view */
  }
`;

const TestimonialText = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 30px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller text on mobile */
    margin-bottom: 20px;
  }
`;

const ClientName = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  color: rgb(235, 213, 16); /* Color for the client's name */
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller font size on mobile */
    text-align: center;
  }
`;

const ClientPosition = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  color: rgb(216, 197, 28); /* Different color for the client's position */
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller font size on mobile */
    text-align: center;
  }
`;
