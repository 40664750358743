import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import styled from "styled-components";
import LogoIcon from "../../assets/svg/Logo";
import angleDown from "../../assets/img/nav/angle-down-solid.svg";
import angleUp from "../../assets/img/nav/angle-up-solid.svg";

export default function DesktopNavbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For mobile menu toggle
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation(); // Get current location (URL)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMouseEnter = (item) => setHoveredItem(item);
  const handleMouseLeave = () => setHoveredItem(null);

  return (
    <Wrapper>
      <NavInner>
        <LogoContainer>
          <RouterLink
            to="/"
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => {
              if (window.location.pathname === "/") {
                e.preventDefault();
                document
                  .getElementById("top")
                  ?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            <LogoIcon />
            <h1
              className="extraBold"
              style={{ marginLeft: "10px", lineHeight: "1.4" }}
            >
              Amruttam Research Institute for Reiki and Yoga
              <br />
              <span style={{ display: "block" }}>Pvt. Ltd.</span>
            </h1>
          </RouterLink>
        </LogoContainer>

        <HamburgerIcon onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </HamburgerIcon>

        <NavLinks className={isMenuOpen ? "open" : ""}>
          {["home", "about", "courses", "services", "products", "contact"].map(
            (item) => (
              <NavItem
                key={item}
                onMouseEnter={() => handleMouseEnter(item)}
                onMouseLeave={handleMouseLeave}
              >
                <StyledRouterLink
                  to={`/${item === "home" ? "" : item}`}
                  onClick={() => setIsMenuOpen(false)} // Close menu on click
                  className={
                    location.pathname === `/${item === "home" ? "" : item}`
                      ? "active"
                      : ""
                  }
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </StyledRouterLink>

                {/* Render icons only for dropdown items */}
                {["about", "courses", "services", "products"].includes(
                  item
                ) && (
                  <IconWrapper>
                    <img
                      src={hoveredItem === item ? angleUp : angleDown}
                      alt="toggle"
                      className="angle-icon"
                    />
                  </IconWrapper>
                )}

                {/* Dropdown for About menu */}
                {item === "about" && hoveredItem === "about" && (
                  <DropdownMenu className="open">
                    <StyledRouterLink
                      to="/about"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      About Us
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/about/gallery"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Gallery
                    </StyledRouterLink>
                  </DropdownMenu>
                )}

                {/* Dropdown for Courses menu */}
                {item === "courses" && hoveredItem === "courses" && (
                  <DropdownMenu className="open">
                    <StyledRouterLink
                      to="/courses/reiki-course"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Reiki Course
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/courses/reiki-1st-and-2nd-degree"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Reiki 1st and 2nd Degree
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/courses/reiki-3rd-degree"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Reiki 3rd Degree
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/courses/pendulum"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Pendulum (Dowsing)
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/courses/reiki-crystal-basic"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Reiki Crystal Basic Course
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/courses/reiki-personalized"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Reiki Crystal Personalized Course
                    </StyledRouterLink>
                  </DropdownMenu>
                )}

                {/* Dropdown for Services menu */}
                {item === "services" && hoveredItem === "services" && (
                  <DropdownMenu className="open">
                    <StyledRouterLink
                      to="/services/all-services"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      All Services
                    </StyledRouterLink>
                  </DropdownMenu>
                )}

                {/* Dropdown for Products menu */}
                {item === "products" && hoveredItem === "products" && (
                  <DropdownMenu className="open">
                    <StyledRouterLink
                      to="/products/arpg"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      ARPG
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/products/amruttam-batti"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Amruttam Batti
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/products/life-protector"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Life Protector
                    </StyledRouterLink>
                    <StyledRouterLink
                      to="/products/store"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Store
                    </StyledRouterLink>
                  </DropdownMenu>
                )}
              </NavItem>
            )
          )}
        </NavLinks>
      </NavInner>
    </Wrapper>
  );
}

// Styled Components

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 10px 0;

  /* For tablet screens between 768px and 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 8px 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  /* Hide Desktop Navbar on small screens */
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  min-height: 80px;
  padding: 0 20px;

  /* Adjust padding for tablet screens */
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 15px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;

  h1 {
    font-size: clamp(
      19px,
      1.5vw,
      22px
    ); /* Adjusted font size for better readability */
    margin-left: 10px;
    line-height: 1.4;

    span {
      display: block;
    }
  }

  img,
  svg {
    max-height: 60px;
    width: auto;
  }

  /* Adjust logo for tablet screens */
  @media (min-width: 768px) and (max-width: 1024px) {
    h1 {
      font-size: clamp(17px, 1.3vw, 20px); /* Slightly smaller font size */
    }
    img,
    svg {
      max-height: 50px; /* Smaller logo for tablets */
    }
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  li {
    margin: 0 12px; /* Reduce spacing between links for medium screens */
  }

  /* Adjust spacing for tablet screens */
  @media (min-width: 768px) and (max-width: 1024px) {
    li {
      margin: 0 10px;
    }
  }
`;

const NavItem = styled.li`
  display: flex; /* Flex to allow inline alignment of text and icon */
  align-items: center; /* Align the icon and text vertically */
  margin: 0 15px;
  position: relative;

  &:hover .dropdown-menu {
    display: block; /* Ensure dropdown shows on hover */
  }
`;

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
  color: #04016a;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  /* Adjust font size for tablets */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;

  .bar {
    width: 30px;
    height: 4px;
    background-color: #04016a;
    border-radius: 5px;
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%; /* Position directly below the parent link */
  left: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: auto; /* Make the width flexible */
  z-index: 10;
  border-radius: 5px;
  display: flex;
  flex-direction: column; /* Display menu items in a single row */
  padding: 0;
  max-height: 0; /* Initially set max-height to 0 for animation */
  opacity: 0; /* Initially hidden */
  overflow: hidden; /* Hide content that exceeds max-height */
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out; /* Slide and fade effect */

  /* When the menu is active (hovered over "About"), expand it */
  &.open {
    max-height: 500px; /* Set a high enough max-height to show all menu items */
    opacity: 1; /* Show the menu */
  }

  & > a {
    padding: 10px 15px;
    display: inline-block; /* Ensure links stay in a line */
    color: #04016a;
    text-decoration: none;
    font-size: 16px;
    white-space: nowrap; /* Prevent wrapping of text */
    overflow: hidden; /* Hide any overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */

    &:hover {
      background-color: #f1f1f1;
    }
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-left: 8px; /* Add spacing between the link text and icon */
  width: 16px; /* Adjust the size of the icon */
  height: 16px;
  position: relative;

  .angle-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
