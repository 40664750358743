import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";

import BharattSir from "../../assets/img/BharattSir.jpg";
import AnjanaMam from "../../assets/img/AnjanaMam.jpg";
import GrishmaMam from "../../assets/img/GrishmaMam.jpg";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <Wrapper>
      <ContentWrapper>
        <HeadingWithMargin id="about">How It All Began...</HeadingWithMargin>

        <SubHeading>
          Our founder Director Bharatt Mistry & the Introduction of Reiki in his
          life
        </SubHeading>
        <Layout>
          <TextWrapper>
            <ScrollableParagraph>
              {/* Full content about Bharatt Mistry */}
              <p>
                In 1993, the first ever experience of Reiki led Reiki master
                Bharatt Mistry to believe in it & the start of his Medicine Free
                Life. Ever since the age of 10, he practiced Yogasan, Pranayam,
                and Meditation. Being a health enthusiast, he later joined the
                Hindu Milan Mandir Vyayam Shala, conducted by the monks of
                Bharat Sevashram Sangha, founded by Swami Pranavanandji Maharaj
                in West Bengal...
              </p>
              <p>
                With the title of best cadet in NCC 3 years in a row, he had
                also served in the team of Satya Shodhak Sabha (The society of
                Truth seekers) & was officer commanding of Surat city in Home
                guards. However during all this time since his birth in 1960,
                his life wasn’t exactly medicine free!
              </p>
              <p>
                Even though leading a healthy lifestyle & regular practice of
                meditation, minor ailments & their medicines were still part of
                his life! But in his late 20’s, the Reiki word entered his life.
              </p>
              <p>
                Being in the field of construction as a builder along with his
                other achievements, Bharatt Mistry has built one of the most
                notable structures in the history of Surat, The Meru Lakshmi
                Mandir in Tadwadi, Rander road,Surat, the biggest Kurma Prastha
                Shree Yantra in RCC work, 80’ * 80’ * 131’ in size, the project
                that he started in his young age of 26.
              </p>
              <p>
                During this period, several prominent figures in the Surat
                construction industry approached him with encouraging words.
              </p>
              <p>
                “Bharattbhai,” they said, “you should explore Reiki. The realms
                of Yoga and Reiki truly need your unique talents.”
              </p>
              <p>
                Additionally, during a visit to the family pediatrician—who also
                happened to be a skilled Reiki practitioner—he witnessed her
                harnessing the healing energy of Reiki to treat her young
                patients.{" "}
              </p>
              <p>
                In the end in 1993, when he fell severely ill because of
                diarrhea, after 50-60 motions in a day, his mother gave him
                Reiki treatment, which resulted in complete recovery only within
                a day did he believed that Yes, there is some type of power of
                Reiki out there, which is capable in healing ailments without
                any medicine. Following the incident he learnt Reiki & that was
                the year 1993, when his journey of Medicine Free Life started.
              </p>
              <p>
                Although he learnt Reiki in 1993, he never wanted to make it a
                profession! Yes there was the internal calling, some of his
                colleagues also told him to become a Reiki master but he said,
                “It’s a divine knowledge, How can I make it a profession?”
              </p>
              <p>
                But as he later heard from his good friend, even the nominal
                work of cleaning by a cleaner is divine work too, because it is
                the basic necessity of human life & survival.
              </p>
              <p>
                Following this, to grow together, Bharatt Mistry suggested his
                wife Anjana Mistry to learn Reiki. After she learnt Reiki &
                later on became a Reiki master. He practiced Reiki for its many
                many uses & got positive results through Reiki in those 6 years,
                to follow his life calling, he decided to become a Reiki master
                himself in 1999. The journey afterwards is life changing!
              </p>
              <p>
                Since 1999, Reiki Master Bharatt Mistry has done much research
                in the Field of Reiki. Being a Sadhak and science student with
                strong intuition he had always felt that the power of Reiki
                currently prevailing in the world is not enough for current
                human lifestyle. Following this inspiration, he kept increasing
                his application of Reiki & started doing his meditation Sadhna
                more intensely. The result was equally powerful! As his Sadhana
                increased, his mastery & siddhis in Reiki also increased. This
                intense Sadhana led to the opening & activation of his nadis
                (energy channels), which in turn increased the intensity of
                Reiki power & its capacity to heal.
              </p>
              <p>
                After expanding his own Nadis, by the grace of Reiki & Dr. Mikao
                Usui, Bharatt Mistry found a way to increase the intensity &
                power of Reiki attunement. Year by Year the Reiki attunements
                discovered by him became more & more powerful & their capacity
                of opening nadis of a Reiki channel increased from tens to
                thousand folds. Then came a pivotal point in his Reiki Sadhana
                that his Reiki attunement power increased to the extent,
                enabling him to expand & enhance the nadis (subtle energy
                channels). This breakthrough allowed for the efficient clearing
                of imbalances & negative data that turned into the power of
                clearing Prana shakti, Chaitanya Shakti & Kundalini Shakti. This
                breakthrough enabled him to optimize the Reiki course, making it
                more effective and efficient in producing rapid results.
              </p>
            </ScrollableParagraph>
          </TextWrapper>
          <ImageWrapper>
            <FounderImageStyled src={BharattSir} alt="BharattKumar Mistry" />
            <FounderName>BharattKumar Mistry</FounderName>
          </ImageWrapper>
        </Layout>

        <MeetFoundersTitle>Meet Our Founders</MeetFoundersTitle>
        <TeamWrapper>
          <TeamMember>
            <FounderImageStyled src={AnjanaMam} alt="Anjana Mistry" />
            <TeamInfo>
              <Name>Anjana Mistry</Name>
              <Position>Director of Training</Position>
            </TeamInfo>
          </TeamMember>
          <TeamMember>
            <FounderImageStyled src={GrishmaMam} alt="Grishma Mistry" />
            <TeamInfo>
              <Name>Grishma Mistry</Name>
              <Position>Director of Research</Position>
            </TeamInfo>
          </TeamMember>
        </TeamWrapper>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px 0 0 0; // Ensure no padding is applied here
  margin: 0; // Remove margin around the wrapper
  width: 100%; // Ensure the wrapper takes full width

  @media (max-width: 768px) {
    padding-top: 25px; /* Reduce padding-top for tablet screens */
  }

  @media (max-width: 480px) {
    padding-top: 25px; /* Further reduce padding-top for small mobile screens */
  }
`;

const ContentWrapper = styled.div`
  flex: 1; /* Takes up all available space except footer */
  max-width: 900px;
  margin: 20px auto; /* Center content */
  padding: 20px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px; /* Reduce padding for tablet screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Further reduce padding for small mobile screens */
  }
`;

const HeadingWithMargin = styled.h1`
  margin-top: 40px; /* Add margin to create space between button and heading */
  font-size: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 30px; /* Reduce top margin on tablet screens */
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    margin-top: 20px; /* Further reduce margin-top for mobile screens */
    font-size: 1.6rem;
  }
`;

const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TextWrapper = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ScrollableParagraph = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 10px;
  line-height: 1.6;
  font-size: 1.1rem;

  p {
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6e8cc3;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
  }
`;

const ImageWrapper = styled.div`
  flex: 0 0 300px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
`;

const FounderImageStyled = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
`;

const SubHeading = styled.h2`
  margin: 20px auto; /* Ensures vertical margin is consistent */
  font-size: 1.5rem;
  color: #2c3e50;
  text-align: center; /* This centers the text horizontally */
  width: fit-content; /* Ensures the element takes only as much space as needed */
`;

const MeetFoundersTitle = styled.h2`
  margin-top: 40px;
  text-align: center;
  font-size: 1.8rem;
`;

const TeamWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TeamMember = styled.div`
  text-align: center;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    height: 280px; /* Prevent image cut-off */
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border: 2px solid #6e8cc3;
  }
`;

const TeamInfo = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(192, 192, 192, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const Name = styled.strong`
  font-size: 1.3rem;
  color: #2c3e50;
  display: block;
  margin-bottom: 5px;
`;

const Position = styled.p`
  margin: 5px 0;
  font-size: 1rem;
  color: #7f8c8d;
  font-style: italic;
`;

const FounderName = styled.div`
  text-align: center;
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-image: linear-gradient(45deg, #a67c00 70%, #04016a 30%);
  color: white;
  padding: 10px 20px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
  transition: transform 0.3s, background-position 1s;

  /* Continuous flowing gradient effect */
  background-size: 400% 400%; /* Large background for animation */
  animation: gradientFlow 2s ease infinite;

  &:hover {
    transform: scale(1.1);
  }

  /* Keyframes to animate the gradient */
  @keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
